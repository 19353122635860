import moment from 'moment';
import { parsePhoneNumber, isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import axios from 'axios';
import { auth } from '../firebase';

const CryptoJS = require('crypto-js');
const emojiRegex = require('emoji-regex');

const gtconfig = require('../gtconfig');

export function Encrypt(salt, text) {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

const ACCESS_LEVELS_LOCAL = {
  GT_ADMIN: 'GT_ADMIN',
  GT_VIEW_ADMIN: 'GT_VIEW_ADMIN',
  PARTNER_ADMIN: 'PARTNER_ADMIN',
  PARTNER_MANAGER: 'PARTNER_MANAGER',
  OWNER: 'OWNER',
  GROUP_ADMIN: 'GROUP_ADMIN',
  GROUP_ADMIN_READ_ONLY: 'GROUP_ADMIN_READ_ONLY',
  GROUP_ADMIN_NO_USR_MGMT: 'GROUP_ADMIN_NO_USR_MGMT',
  LOCATION_ADMIN: 'LOCATION_ADMIN', // manager
  GENERIC_USER: 'GENERIC_USER', // frontdesk
  RG_ADMIN: 'RG_ADMIN',
  RG_GENERIC_USER: 'RG_GENERIC_USER',
};
export const ACCESS_LEVELS = ACCESS_LEVELS_LOCAL;

export const ACCESS_LEVELS_TO_LABEL = {
  'GT_ADMIN': 'GT Admin',
  'GT_VIEW_ADMIN': 'GT View Admin',
  'PARTNER_ADMIN': 'Partner Admin',
  'PARTNER_MANAGER': 'Partner Manager',
  'OWNER': 'Owner',
  'GROUP_ADMIN': 'Group Admin',
  'GROUP_ADMIN_READ_ONLY': 'Group Admin - Read Only',
  'GROUP_ADMIN_NO_USR_MGMT': 'Group Admin - Without User Management',
  'LOCATION_ADMIN': 'Location Admin',
  'GENERIC_USER': 'Generic User',
  'RG_ADMIN': 'RG Admin',
  'RG_GENERIC_USER': 'RG Generic User',
};

const RG_SUBS_LOCAL = {
  PAID: 'paid',
  TRIAL: 'trial'
}
export const RG_SUBS = RG_SUBS_LOCAL;

const RG_PLAN_LOCAL = {
  PRO: 'pro',
  BASIC: 'basic',
};
export const RG_PLAN = RG_PLAN_LOCAL;

const RG_PLAN_LABEL_LOCAL = {
  pro: 'Pro',
  basic: 'Essential',
};
export const RG_PLAN_LABEL = RG_PLAN_LABEL_LOCAL;

export function bookmarksContain(bookmarks, link) {
  let result = false;
  if (bookmarks.find(el => el.link === link)) result = true;
  return result;
}

export function getUserGAId(user) {
  let result = user.user_id || '';
  if (result && user.user_info && user.user_info.profile) {
    if (user.user_info.profile.first_name) {
      result = `${result} - ${user.user_info.profile.first_name}`;
    }
    if (user.user_info.profile.last_name) {
      result = `${result} ${user.user_info.profile.last_name}`;
    }
  }
  return result;
}

export function isGTUser(user) {
  if (user.user_info && user.user_info.profile && user.user_info.profile.access_level) {
    if (user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN
    ) {
      return true;
    }
  }
  return false;
}

export function hasProductAccess(user, locationMId, product) {
  if (!user.user_info) return false;
  if (!locationMId) return false;
  if (user.user_info.profile.access_level) {
    if (
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.OWNER ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN
    ) return true;
  }
  if (user.user_info.profile.locations && user.user_info.profile.locations[locationMId] &&
    user.user_info.profile.locations[locationMId].access_level &&
    (user.user_info.profile.locations[locationMId].access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
      user.user_info.profile.locations[locationMId].access_level === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN ||
      user.user_info.profile.locations[locationMId].access_level === ACCESS_LEVELS_LOCAL.GENERIC_USER
    )
  ) {
    return true;
  }
  if (user.user_info.profile.locations && user.user_info.profile.locations[locationMId] &&
    user.user_info.profile.locations[locationMId][product] &&
    user.user_info.profile.locations[locationMId][product].access_level &&
    (
      // user.user_info.profile.locations[locationMId][product].access_level === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
      // user.user_info.profile.locations[locationMId][product].access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
      user.user_info.profile.locations[locationMId][product].access_level === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN ||
      user.user_info.profile.locations[locationMId][product].access_level === ACCESS_LEVELS_LOCAL.GENERIC_USER
    )
  ) return true;
  return false;
}



export function isRGUser(user) {
  if (user.user_info && user.user_info.profile && user.user_info.profile.rg_access_level) {
    if (user.user_info.profile.rg_access_level === ACCESS_LEVELS_LOCAL.RG_ADMIN ||
      user.user_info.profile.rg_access_level === ACCESS_LEVELS_LOCAL.RG_GENERIC_USER
    ) {
      return true;
    }
  }
  return false;
}
export function isRGUserOnly(user) {
  if (user.user_info && user.user_info.profile && user.user_info.profile.rg_access_level &&
    !user.user_info.profile.locations
  ) {
    if (user.user_info.profile.rg_access_level === ACCESS_LEVELS_LOCAL.RG_ADMIN ||
      user.user_info.profile.rg_access_level === ACCESS_LEVELS_LOCAL.RG_GENERIC_USER
    ) {
      return true;
    }
  }
  return false;
}

function isRGAdminLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.RG_ADMIN
  ) {
    return true;
  }
  return false;
}
export function isRGAdmin(accessLevel) {
  return isRGAdminLocal(accessLevel);
}




function isGenericAndAboveLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
    accessLevel === ACCESS_LEVELS_LOCAL.OWNER ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_NO_USR_MGMT ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_READ_ONLY ||
    accessLevel === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GENERIC_USER
  ) {
    return true;
  }
  return false;
}
export function isGenericAndAbove(accessLevel) {
  return isGenericAndAboveLocal(accessLevel);
}

function isLocationAdminAndAboveLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
    accessLevel === ACCESS_LEVELS_LOCAL.OWNER ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_NO_USR_MGMT ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_READ_ONLY ||
    accessLevel === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN
  ) {
    return true;
  }
  return false;
}
export function isLocationAdminAndAbove(accessLevel) {
  return isLocationAdminAndAboveLocal(accessLevel);
}

function isGroupAdminAndAboveLocal(accessLevel) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('isGroupAdminAndAboveLocal: ', accessLevel);
  }
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
    accessLevel === ACCESS_LEVELS_LOCAL.OWNER ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN
  ) {
    return true;
  }
  return false;
}
export function isGroupAdminAndAbove(accessLevel) {
  return isGroupAdminAndAboveLocal(accessLevel);
}

function isOwnerAndAboveLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
    accessLevel === ACCESS_LEVELS_LOCAL.OWNER
  ) {
    return true;
  }
  return false;
}
export function isOwnerAndAbove(accessLevel) {
  return isOwnerAndAboveLocal(accessLevel);
}

function isPartnerManagerAndAboveLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER
  ) {
    return true;
  }
  return false;
}
export function isPartnerManagerAndAbove(accessLevel) {
  return isPartnerManagerAndAboveLocal(accessLevel);
}

function isPartnerAdminAndAboveLocal(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN
  ) {
    return true;
  }
  return false;
}
export function isPartnerAdminAndAbove(accessLevel) {
  return isPartnerAdminAndAboveLocal(accessLevel);
}


export function hasAccessToUserManagment(accessLevel) {
  if (!accessLevel) return false;
  if (accessLevel === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
    accessLevel === ACCESS_LEVELS_LOCAL.OWNER ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
    accessLevel === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_READ_ONLY ||
    accessLevel === ACCESS_LEVELS_LOCAL.LOCATION_ADMIN
  ) {
    return true;
  }
  return false;
}


export function hasAccessToManagerAndAbove(user) { // nkcheck
  // if (user.user_info && user.user_info.gt_profile && user.user_info.gt_profile.engage_access_level) {
  //   if (user.user_info.gt_profile.engage_access_level === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
  //     user.user_info.gt_profile.engage_access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
  //     user.user_info.gt_profile.engage_access_level === ACCESS_LEVELS_LOCAL.MANAGER
  //   ) {
  //     return true;
  //   }
  // }
  if (user.user_info && user.user_info.profile && user.user_info.profile.access_level) {
    if (user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GT_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GT_VIEW_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.PARTNER_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.PARTNER_MANAGER ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.OWNER ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.GROUP_ADMIN_NO_USR_MGMT ||
      user.user_info.profile.access_level === ACCESS_LEVELS_LOCAL.MANAGER
    ) {
      return true;
    }
  }
  return false;
}

export function curUserHasAccessToOtherUserByAccessLevel(curUserAccessLevel, otherUserAccessLevel) {
  if (curUserAccessLevel === ACCESS_LEVELS.GT_ADMIN || curUserAccessLevel === ACCESS_LEVELS.GT_VIEW_ADMIN) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.PARTNER_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.PARTNER_MANAGER ||
      otherUserAccessLevel === ACCESS_LEVELS.OWNER ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT ||
      otherUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  if (curUserAccessLevel === ACCESS_LEVELS.PARTNER_ADMIN) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.PARTNER_MANAGER ||
      otherUserAccessLevel === ACCESS_LEVELS.OWNER ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT ||
      otherUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  if (curUserAccessLevel === ACCESS_LEVELS.PARTNER_MANAGER) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.OWNER ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT ||
      otherUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  if (curUserAccessLevel === ACCESS_LEVELS.OWNER) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
      otherUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT ||
      otherUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  if (curUserAccessLevel === ACCESS_LEVELS.GROUP_ADMIN) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  if (curUserAccessLevel === ACCESS_LEVELS.LOCATION_ADMIN) {
    if (!otherUserAccessLevel ||
      otherUserAccessLevel === ACCESS_LEVELS.GENERIC_USER
    ) { return true; }
  }
  return false;
}

export function isLocationInUserInfo(userInfo, locationidentifier) {
  let hasLocation = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('isLocationInUserInfo: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile && userInfo.profile.access_level === ACCESS_LEVELS.GT_ADMIN) {
    hasLocation = true;
    return {
      hasLocation
    };
  }
  if (locationidentifier && userInfo && userInfo.profile && userInfo.profile.locations &&
    userInfo.profile.locations[locationidentifier]) {
    hasLocation = true;
  }
  return {
    hasLocation
  };
};

export function getProductAccess(userInfo, locationidentifier) {
  let hasAccessToReputation = false, hasAccessToEngage = false, hasAccessToMarketing = false, hasAccessToResponseGenerator = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getProductAccess: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    if (isLocationAdminAndAboveLocal(profile.access_level)) {
      hasAccessToReputation = true;
      hasAccessToEngage = true;
      hasAccessToMarketing = true;
      hasAccessToResponseGenerator = true;
      return {
        hasAccessToReputation, hasAccessToEngage, hasAccessToMarketing, hasAccessToResponseGenerator
      };
    }
    if (isRGAdminLocal(profile.rg_access_level) || profile.rg_access_level === ACCESS_LEVELS.RG_GENERIC_USER) {
      hasAccessToResponseGenerator = true;
      return {
        hasAccessToReputation, hasAccessToEngage, hasAccessToMarketing, hasAccessToResponseGenerator
      };
    }

    if (profile.locations && profile.locations[locationidentifier]) {
      if (profile.locations[locationidentifier].reputation &&
        (
          (
            profile.locations[locationidentifier].reputation.access_level &&
            isGenericAndAboveLocal(profile.locations[locationidentifier].reputation.access_level)
          ) ||
          profile.locations[locationidentifier].access_level === 'GENERIC_USER'
        )
      ) {
        hasAccessToReputation = true;
      }
      if (profile.locations[locationidentifier].engage &&
        (
          (
            profile.locations[locationidentifier].engage.access_level &&
            isGenericAndAboveLocal(profile.locations[locationidentifier].engage.access_level)
          ) ||
          profile.locations[locationidentifier].access_level === 'GENERIC_USER'
        )
      ) {
        hasAccessToEngage = true;
      }
      if (profile.locations[locationidentifier].marketing &&
        (
          (
            profile.locations[locationidentifier].marketing.access_level &&
            isGenericAndAboveLocal(profile.locations[locationidentifier].marketing.access_level)
          ) ||
          profile.locations[locationidentifier].access_level === 'GENERIC_USER'
        )
      ) {
        hasAccessToMarketing = true;
      }
      if (profile.locations[locationidentifier].response_generator &&
        (
          (
            profile.locations[locationidentifier].response_generator.access_level &&
            isGenericAndAboveLocal(profile.locations[locationidentifier].response_generator.access_level)
          ) ||
          profile.locations[locationidentifier].access_level === 'GENERIC_USER'
        )
      ) {
        hasAccessToResponseGenerator = true;
      }
      if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].access_level)) {
        hasAccessToReputation = true;
        hasAccessToEngage = true;
        hasAccessToMarketing = true;
        hasAccessToResponseGenerator = true;
      }
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getProductAccess hasAccessToReputation: ', hasAccessToReputation);
    console.log('getProductAccess hasAccessToMarketing: ', hasAccessToMarketing);
    console.log('getProductAccess hasAccessToEngage: ', hasAccessToEngage);
    console.log('getProductAccess hasAccessToResponseGenerator: ', hasAccessToResponseGenerator);
  }
  return {
    hasAccessToReputation, hasAccessToEngage, hasAccessToMarketing, hasAccessToResponseGenerator
  };
};

export function getHighestAccessLevels(userInfo, locationidentifier) {
  let accessLevel = '', accessLevelReputation = '', accessLevelEngage = '', accessLevelMarketing = '';
  if (process.env.NODE_ENV !== 'production') {
    console.log('getHighestAccessLevels: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    if (profile.access_level) {
      accessLevel = accessLevelReputation = accessLevelEngage = accessLevelMarketing = profile.access_level;
      return { accessLevel, accessLevelReputation, accessLevelEngage, accessLevelMarketing };
    }
    if (profile.locations && profile.locations[locationidentifier]) {
      if (profile.locations[locationidentifier].access_level) {
        accessLevel = accessLevelReputation = accessLevelEngage = accessLevelMarketing = profile.locations[locationidentifier].access_level;
        return { accessLevel, accessLevelReputation, accessLevelEngage, accessLevelMarketing };
      }
      if (profile.locations[locationidentifier].reputation && profile.locations[locationidentifier].reputation.access_level) {
        if (profile.locations[locationidentifier].reputation.access_level) {
          accessLevelReputation = profile.locations[locationidentifier].reputation.access_level;
        }
      }
      if (profile.locations[locationidentifier].engage && profile.locations[locationidentifier].engage.access_level) {
        if (profile.locations[locationidentifier].engage.access_level) {
          accessLevelEngage = profile.locations[locationidentifier].engage.access_level;
        }
      }
      if (profile.locations[locationidentifier].marketing && profile.locations[locationidentifier].marketing.access_level) {
        if (profile.locations[locationidentifier].marketing.access_level) {
          accessLevelMarketing = profile.locations[locationidentifier].marketing.access_level;
        }
      }
      return { accessLevel, accessLevelReputation, accessLevelEngage, accessLevelMarketing };
    }
  }
  return { accessLevel, accessLevelReputation, accessLevelEngage, accessLevelMarketing };
};



export const isPasswordStrong = (value) => {
  if (!value) return false;
  return value.length >= 8;
};

export const PRODUCT_TO_LABELS = {
  'reputation': 'Reputation',
  'engage': 'Engage',
  'marketing': 'Marketing',
  'response-generator': 'ReviewResponseAI',
}

export const REPUTATION_FEATURES_TO_LABELS = {
  'respond-to-reviews': 'Respond to reviews',
  'run-feedback-campaign': 'Run feedback campaign',
};
export const ENGAGE_FEATURES_TO_LABELS = {
  'engage-basic': 'Engage Basic',
  'reply-to-guest-messages': 'Send and reply to guests messages',
  'add-edit-guests': 'Add/Edit Guests',
  'reply-to-webchat-messages': 'Reply to Webchat messages',
  'manage-hotel-chat-template-settings': 'Manage hotel, chat and template settings',
  'configure-webchat': 'Configure WebChat',
  'configure-chatbot': 'Configure ChatBot',
  'broadcast': 'Broadcast',
};
export const MARKETING_FEATURES_TO_LABELS = {
  'manage-transaction-emails': 'Manage Transaction Emails',
  'manage-email-marketing': 'Manage Email Marketing',
};
export const RG_FEATURES_TO_LABELS = {
  // 'respond-to-reviews': 'Respond to reviews',
  'configure': 'Configure',
};

export function getEngageAccess(userInfo, locationidentifier) {
  let hasAccessToWebChatReply = false, hasAccessToWebChatConfigure = false, hasAccessToBroadcast = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getEngageAccess: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    // check at profile level
    // if (isGroupAdminAndAboveLocal(profile.access_level))
    if (isLocationAdminAndAboveLocal(profile.access_level)) {
      hasAccessToWebChatReply = hasAccessToWebChatConfigure = hasAccessToBroadcast = true;
      return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
    }
    // check at location level
    if (profile.locations && profile.locations[locationidentifier]) {
      if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].access_level)) {
        hasAccessToWebChatReply = hasAccessToWebChatConfigure = hasAccessToBroadcast = true;
        return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
      }
      if (profile.locations[locationidentifier].access_level === 'GENERIC_USER') { // look into features
        if (profile.locations[locationidentifier].engage && profile.locations[locationidentifier].engage.features) {
          hasAccessToWebChatReply = profile.locations[locationidentifier].engage.features['reply-to-webchat-messages'] ? true : false;
          hasAccessToWebChatConfigure = profile.locations[locationidentifier].engage.features['configure-webchat'] ? true : false;
          hasAccessToBroadcast = profile.locations[locationidentifier].engage.features['broadcast'] ? true : false;
        }
        return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
      }
      if (profile.locations[locationidentifier].engage && profile.locations[locationidentifier].engage.access_level) {
        if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].engage.access_level)) {
          hasAccessToWebChatReply = hasAccessToWebChatConfigure = hasAccessToBroadcast = true;
          return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
        }
        if (profile.locations[locationidentifier].engage.access_level === 'GENERIC_USER') { // look into features
          if (profile.locations[locationidentifier].engage.features) {
            hasAccessToWebChatReply = profile.locations[locationidentifier].engage.features['reply-to-webchat-messages'] ? true : false;
            hasAccessToWebChatConfigure = profile.locations[locationidentifier].engage.features['configure-webchat'] ? true : false;
            hasAccessToBroadcast = profile.locations[locationidentifier].engage.features['broadcast'] ? true : false;
          }
          return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
        }
      }
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getEngageAccess hasAccessToBroadcast: ', hasAccessToBroadcast);
  }
  return { hasAccessToWebChatReply, hasAccessToWebChatConfigure, hasAccessToBroadcast };
}


export function getReputationAccess(userInfo, locationidentifier) {
  let hasAccessToRunFeedbackCampaign = false, hasAccessToRespondToReviews = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getReputationAccess: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    if (isGroupAdminAndAboveLocal(profile.access_level)) {
      hasAccessToRunFeedbackCampaign = hasAccessToRespondToReviews = true;
      return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
    }
    if (profile.access_level && isLocationAdminAndAboveLocal(profile.access_level)) {
      hasAccessToRunFeedbackCampaign = hasAccessToRespondToReviews = true;
      return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
    }
    if (profile.locations && profile.locations[locationidentifier]) {
      if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].access_level)) {
        hasAccessToRunFeedbackCampaign = hasAccessToRespondToReviews = true;
        return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
      }
      if (profile.locations[locationidentifier].access_level === 'GENERIC_USER') { // look into features
        if (profile.locations[locationidentifier].reputation && profile.locations[locationidentifier].reputation.features) {
          hasAccessToRespondToReviews = profile.locations[locationidentifier].reputation.features['respond-to-reviews'] ? true : false;
          hasAccessToRunFeedbackCampaign = profile.locations[locationidentifier].reputation.features['run-feedback-campaign'] ? true : false;
        }
        return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
      }
      if (profile.locations[locationidentifier].reputation && profile.locations[locationidentifier].reputation.access_level) {
        if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].reputation.access_level)) {
          hasAccessToRunFeedbackCampaign = hasAccessToRespondToReviews = true;
          return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
        }
        if (profile.locations[locationidentifier].reputation.access_level === 'GENERIC_USER') { // look into features
          if (profile.locations[locationidentifier].reputation.features) {
            hasAccessToRespondToReviews = profile.locations[locationidentifier].reputation.features['respond-to-reviews'] ? true : false;
            hasAccessToRunFeedbackCampaign = profile.locations[locationidentifier].reputation.features['run-feedback-campaign'] ? true : false;
          }
          return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
        }
      }
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getReputationAccess hasAccessToRunFeedbackCampaign: ', hasAccessToRunFeedbackCampaign);
    console.log('getReputationAccess hasAccessToRespondToReviews: ', hasAccessToRespondToReviews);
  }
  return { hasAccessToRunFeedbackCampaign, hasAccessToRespondToReviews };
}

export function getMarketingAccess(userInfo, locationidentifier) {
  let hasAccessToManageTransactionEmails = false, hasAccessToManageEmailMarketing = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getMarketingAccess: ', userInfo, ' # ', locationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    if (isGroupAdminAndAboveLocal(profile.access_level)) {
      hasAccessToManageTransactionEmails = hasAccessToManageEmailMarketing = true;
      return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
    }
    if (profile.locations && profile.locations[locationidentifier]) {
      if (isLocationAdminAndAboveLocal(profile.access_level) ||
        isLocationAdminAndAboveLocal(profile.locations[locationidentifier].access_level)
      ) {
        hasAccessToManageTransactionEmails = hasAccessToManageEmailMarketing = true;
        return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
      }
      if (profile.locations[locationidentifier].access_level === 'GENERIC_USER') { // look into features
        if (profile.locations[locationidentifier].marketing && profile.locations[locationidentifier].marketing.features) {
          hasAccessToManageTransactionEmails = profile.locations[locationidentifier].marketing.features['manage-transaction-emails'] ? true : false;
          hasAccessToManageEmailMarketing = profile.locations[locationidentifier].marketing.features['manage-email-marketing'] ? true : false;
        }
        return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
      }
      if (profile.locations[locationidentifier].marketing && profile.locations[locationidentifier].marketing.access_level) {
        if (isLocationAdminAndAboveLocal(profile.locations[locationidentifier].marketing.access_level)) {
          hasAccessToManageTransactionEmails = hasAccessToManageEmailMarketing = true;
          return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
        }
        if (profile.locations[locationidentifier].marketing.access_level === 'GENERIC_USER') { // look into features
          if (profile.locations[locationidentifier].marketing.features) {
            hasAccessToManageTransactionEmails = profile.locations[locationidentifier].marketing.features['manage-transaction-emails'] ? true : false;
            hasAccessToManageEmailMarketing = profile.locations[locationidentifier].marketing.features['manage-email-marketing'] ? true : false;
          }
          return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
        }
      }
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getMarketingAccess hasAccessToManageTransactionEmails: ', hasAccessToManageTransactionEmails);
  }
  return { hasAccessToManageTransactionEmails, hasAccessToManageEmailMarketing };
}

export function getResponseGeneratorAccess(userInfo, rglocationidentifier) {
  let hasAccessToResponseGenerator = false, hasAccessToResponseGeneratorConfigure = false;
  let hasAccessToResponseGeneratorUsers = false;
  if (process.env.NODE_ENV !== 'production') {
    console.log('getResponseGeneratorAccess: ', userInfo, ' # ', rglocationidentifier);
  }
  if (userInfo && userInfo.profile) {
    const { profile } = userInfo;
    if (isRGAdminLocal(profile.rg_access_level)) {
      hasAccessToResponseGenerator = hasAccessToResponseGeneratorConfigure = true;
      hasAccessToResponseGeneratorUsers = true;
      return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
    }
    if (profile.rg_locations && profile.rg_locations[rglocationidentifier]) {
      // if (isLocationAdminAndAboveLocal(profile.locations[rglocationidentifier].access_level)) {
      //   hasAccessToResponseGenerator = hasAccessToResponseGeneratorConfigure = true;
      //   return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
      // }
      if (profile.rg_locations[rglocationidentifier].rg_access_level === 'RG_GENERIC_USER') { // look into features
        if (profile.rg_locations[rglocationidentifier].features) {
          hasAccessToResponseGenerator = profile.rg_locations[rglocationidentifier].features['generate-response'] ? true : false;
          hasAccessToResponseGeneratorConfigure = profile.rg_locations[rglocationidentifier].features['configure'] ? true : false;
        }
        return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
      }
      if (profile.locations[rglocationidentifier].response_generator && profile.locations[rglocationidentifier].response_generator.access_level) {
        if (isLocationAdminAndAboveLocal(profile.locations[rglocationidentifier].response_generator.access_level)) {
          hasAccessToResponseGenerator = hasAccessToResponseGeneratorConfigure = true;
          return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
        }
        if (profile.locations[rglocationidentifier].response_generator.access_level === 'GENERIC_USER') { // look into features
          if (profile.locations[rglocationidentifier].response_generator.features) {
            hasAccessToResponseGenerator = profile.locations[rglocationidentifier].response_generator.features['generate-response'] ? true : false;
            hasAccessToResponseGeneratorConfigure = profile.locations[rglocationidentifier].response_generator.features['configure'] ? true : false;
          }
          return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
        }
      }
    }
    if (profile.rg_locations && Object.keys(profile.rg_locations).length) {
      let tAccess = false;
      Object.keys(profile.rg_locations).forEach((item) => {
        if (profile.rg_locations[item].features && profile.rg_locations[item].features.configure) {
          tAccess = true;
        }
      });
      hasAccessToResponseGeneratorConfigure = tAccess;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('getResponseGeneratorAccess hasAccessToResponseGenerator: ', hasAccessToResponseGenerator);
  }
  return { hasAccessToResponseGenerator, hasAccessToResponseGeneratorConfigure, hasAccessToResponseGeneratorUsers };
}


const dateDurationOptionsMap = {};
dateDurationOptionsMap['select'] = 'Select';
dateDurationOptionsMap['last-7-days'] = 'Last 7 days';
dateDurationOptionsMap['last-15-days'] = 'Last 15 days';
dateDurationOptionsMap['last-1-month'] = 'Last 1 month';
dateDurationOptionsMap['last-3-months'] = 'Last 3 months';
dateDurationOptionsMap['last-6-months'] = 'Last 6 months';
dateDurationOptionsMap['last-12-months'] = 'Last 12 months';
export const DATE_DURATION_OPTIONS_MAP = dateDurationOptionsMap;

const dateDurationNextOptionsMap = {};
dateDurationNextOptionsMap['select'] = 'Select';
dateDurationNextOptionsMap['next-7-days'] = 'Next 7 days';
dateDurationNextOptionsMap['next-15-days'] = 'Next 15 days';
dateDurationNextOptionsMap['next-1-month'] = 'Next 1 month';
export const DATE_DURATION_NEXT_OPTIONS_MAP = dateDurationNextOptionsMap;


export const TRANSACTION_EMAIL_TYPE_TO_LABEL = {
  'booking': 'Booking',
  'early-pre-arrival': 'Pre-Arrival',
  'pre-arrival': 'Pre-Arrival',
  'check-in': 'Check-In',
  'check-out': 'Check-Out',
  'custom': 'Custom',
};
export const TRANSACTION_EMAIL_CUSTOMIZE_FOR_MAP = {
  'rate-code': 'Rate Code',
  'booking-source': 'Booking Source',
  'room-type': 'Room Type',
  'room-number': 'Room Number',
  'repeat-visitors': 'Repeat visitor',
  'guest-lang': 'Guest Language',
};
export const TRANSACTION_EMAIL_RSRV_TYPE_TO_LABEL = {
  'Reservation': 'Upcoming',
  'CheckIn': 'Checked-In',
  'CheckOut': 'Checked-Out',
  'Cancel': 'Cancelled',
};


export const INSTANT_INVITE_MEDIUM_TO_LABEL = {
  'sms': 'SMS',
  'email': 'Email',
};
export const INSTANT_INVITE_REQUEST_TYPE_TO_LABEL = {
  'selector': 'Selector',
  'tripadvisor': 'Tripadvisor',
  'google': 'Google',
};


export function replaceAll(tstr, find, replace) {
  return tstr.replace(new RegExp(find, 'g'), replace);
}

function replaceAllLocal(tstr, find, replace) {
  return tstr.replace(new RegExp(find, 'g'), replace);
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = re.test(String(email).toLowerCase());
  if (process.env.NODE_ENV !== 'production') {
    console.log('validateEmail email: ', email, ' # ', result);
  }
  return result;
}

export function validateUrl(url) {
  var pattern1 = /^((http|https):\/\/)/;
  var pattern2 = /^((www).)/;
  return pattern1.test(url) || pattern2.test(url);
  // var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  // '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
  // '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
  // '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
  // '(\\#[-a-z\\d_]*)?$','i');
  // return pattern.test(url);
}

export function validateContact(contact) {
  if (!contact) return false;
  if (contact.length < 9 || contact.length > 13) return false;
  return true;
}


export const formatDate = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY');
};
export const formatDateTime = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY hh:mm A');
};
export const formatDateTimeOrig = (date) => {
  return date;
};

export const formatDateSpl = (date) => {
  let fmt = 'Do MMM YYYY';
  if (!moment().isSame(moment(date), 'year')) {
    fmt = 'MM/DD/YY';
  } else {
    fmt = 'MMM DD';
  }
  return moment.utc(date).local().format(fmt);
};

export const formatDateStrToStr = (dateStr) => {
  if (!dateStr) return null;
  return moment(dateStr, 'YYYY-MM-DD').format('Do MMM YYYY');
};

export const formatDateStrToLockAccessStr = (dateStr) => {
  if (!dateStr) return null;
  return moment(dateStr).utc().format('Do MMM YYYY hh:mm A');
};

export function formatMessageDate(date) {
  try {
    if (date) {
      const date_diff = moment(new Date()).diff(moment(date), 'seconds');
      if ((date_diff / 60) > (12 * 60)) {
        return moment.utc(date).local().format('Do MMM YYYY h:m A');
      }
      if ((date_diff / 60) > 60) { // more than 60 mins.... show hours
        return moment.utc(date_diff * 1000).format('h') + ' hrs ' + moment.utc(date_diff * 1000).format('m') + ' mins ago';
      } else if (date_diff > 60) { // more than 60 secs... show mins
        if (date_diff > 1) {
          return moment.utc(date_diff * 1000).format('m') + ' mins ago';
        }
        return moment.utc(date_diff * 1000).format('m') + ' min ago';
      }
      if (date_diff > 1) {
        return moment.utc(date_diff * 1000).format('s') + ' secs ago';
      }
      return 'Just now';
    }
  } catch (e) {
    //
  }
  return date;
}

export function formatDateChange(date) {
  try {
    if (date) {
      const date_diff = moment(new Date()).diff(moment(date), 'seconds');
      if ((date_diff / 60) > (24 * 60)) {
        return moment.utc(date).local().format('Do MMM YYYY');
      }
      return 'Today';
    }
  } catch (e) {
    //
  }
  return date;
}

export function isDateChanged(a, b) {
  const date_diff = moment(a).diff(moment(b), 'seconds');
  if (process.env.NODE_ENV !== 'production') {
    console.log('date_diff: ', date_diff);
  }
  if (date_diff > 60 * 60 * 24) {
    return true;
  }
  return false;
}

export function getQuarters(numberOfQuarters) {
  let result = [];
  for (let i = 0; i < numberOfQuarters; i++) {
    const start = moment().subtract(i, 'quarters').startOf('quarter'); // .format('YYYY-MM-DD'
    const end = moment().subtract(i, 'quarters').endOf('quarter');
    // console.log('start: ', start, ' #', end);
    //console.log('end: ', end);
    result.push({ 'label': `${start.format('MMMYY')} - ${end.format('MMMYY')}`, 'value': `${start.format('YYYY-MM-DD')} ${end.format('YYYY-MM-DD')}` })
  }
  return result;
}
export function getMonths(numberOfMonths) {
  let result = [];
  for (let i = 0; i < numberOfMonths; i++) {
    const start = moment().subtract(i, 'months').startOf('month'); // .format('YYYY-MM-DD'
    const end = moment().subtract(i, 'months').endOf('month');
    // console.log('start: ', start, ' #', end);
    //console.log('end: ', end);
    result.push({ 'label': `${start.format('MMM YYYY')}`, 'value': `${start.format('YYYY-MM-DD')}` })
  }
  return result;
}
export function getWeeks(numberOfWeeks) {
  let result = [];
  for (let i = 0; i < numberOfWeeks; i++) {
    const start = moment().subtract(i, 'weeks').startOf('week'); // .format('YYYY-MM-DD'
    const end = moment().subtract(i, 'weeks').endOf('week');
    // console.log('start: ', start, ' #', end);
    //console.log('end: ', end);
    result.push({ 'label': `${start.format('Do MMM YYYY')} - ${end.format('Do MMM YYYY')}`, 'value': `${start.format('YYYY-MM-DD')} ${end.format('YYYY-MM-DD')}` })
  }
  return result;
}
export function getDays(numberOfDays) {
  let result = [];
  for (let i = 0; i < numberOfDays; i++) {
    const start = moment().subtract(i, 'days').startOf('day'); // .format('YYYY-MM-DD'
    const end = moment().subtract(i, 'day').endOf('day');
    // console.log('start: ', start, ' #', end);
    //console.log('end: ', end);
    result.push({ 'label': `${start.format('Do MMM YYYY')}`, 'value': `${start.format('YYYY-MM-DD')}` })
  }
  return result;
}

export function getCurrentProduct(pathname) {
  if (!pathname) return null;
  if (pathname.indexOf('/reputation') > -1) return 'reputation';
  if (pathname.indexOf('/engage') > -1) return 'engage';
  if (pathname.indexOf('/marketing') > -1) return 'marketing';
  return null;
}

export const productIdToLabels = {
  'reputation': 'Reputation',
  'engage': 'Engage',
  'marketing': 'Marketing',
};

export const ENGAGE_MEDIUM_TO_LABEL = {
  'sms': 'SMS',
  'whatsapp': 'WhatsApp',
  'email': 'Email',
  'webapp': 'WebApp',
  'bm': 'Google Business Message',
  'expedia': 'Expedia',
};

export const categoryToGuestType = {
  'guests': 'guest',
  'past-guests': 'past-guest',
  'upcoming-guests': 'upcoming-guest',
  'outside-guests': 'outside-guest',
  'gbm': 'gbm',
  'staff': 'staff',
  'webtext': 'webtext',
};

export const sortOptionLabels = {
  'unread': 'Unread',
  'recent': 'Default',
  'upcoming': 'Default',
  'incoming': 'Incoming',
  'cancelled': 'Cancelled',
};


// features check // nkcheck

export const categoryToStoreGuestLabel = {
  'guests': 'guests',
  'past-guests': 'pastGuests',
  'upcoming-guests': 'upcomingGuests',
  'outside-guests': 'outsideGuests',
  'gbm': 'gbm',
  'staff': 'staff',
  'webtext': 'webtext',
};

export function getGuestTypeKey(guestType) {
  switch (guestType) {
    case 'guest':
    case 'guests':
      return 'guests';
    case 'past-guest':
    case 'past-guests':
      return 'pastGuests';
    case 'upcoming-guest':
    case 'upcoming-guests':
      return 'upcomingGuests';
    case 'outside-guest':
    case 'outside-guests':
      return 'outsideGuests';
    case 'staff':
      return 'staff';
    case 'webtext':
      return 'webtext';
    case 'gbm':
      return 'gbm';
    default:
      return 'guests';
  }
};

export const getCategoryIdByGuestKey = {
  'guests': 'guests',
  'pastGuests': 'past-guests',
  'upcomingGuests': 'upcoming-guests',
  'outsideGuests': 'outside-guests',
  'webtext': 'webtext',
  'staff': 'staff',
};

export const guestTypeKeyByTransactionStatus = {
  'CheckIn': 'guests',
  'CheckOut': 'pastGuests',
  'Reservation': 'upcomingGuests',
  'Booking': 'upcomingGuests',
  'Cancel': 'upcomingGuests', // 'upcomingGuests', // 'pastGuests'
};

export const guestTransactionStatusToStayLabel = {
  'CheckIn': 'Staying in',
  'CheckOut': 'Checked Out',
  'Reservation': 'Upcoming',
  'Booking': 'Upcoming',
  'Cancel': 'Cancelled',
};

export const guestTypeToGenericLabel = {
  'guests': 'Guest',
  'guest': 'Guest',
  'past-guests': 'Guest',
  'past-guest': 'Guest',
  'upcoming-guests': 'Guest',
  'upcoming-guest': 'Guest',
  'outside-guests': 'Direct contact',
  'outside-guest': 'Direct contact',
  'staff': 'Staff',
  'webtext': 'WebChat contact',
  'gbm': 'Google Message',
};

export function getGuestCategoryKeyByGuest(guest) {
  if (guest.is_staff) return 'staff';
  if (guest.source === 'webtext') return 'webtext';
  if (guest.source === 'bm') return 'gbm';
  if (!guest.transaction_type) return 'outside-guests';
  if (guest.transaction_type === 'CheckIn') return 'guests';
  if (guest.transaction_type === 'CheckOut') return 'past-guests';
  if (guest.transaction_type === 'Reservation' || guest.transaction_type === 'Booking') return 'upcoming-guests';
  if (guest.transaction_type === 'Cancel') return 'past-guests';
  return '';
}

export const guestCategoryKeyByGuestType = {
  'guest': 'guests',
  'past-guest': 'past-guests',
  'upcoming-guest': 'upcoming-guests',
  'outside-guest': 'outside-guests',
  'webtext': 'webtext',
  'gbm': 'gbm',
  'staff': 'staff',
}

export const guestLabelByGuestType = {
  'guest': 'Checked In',
  'past-guest': 'Checked Out',
  'upcoming-guest': 'Upcoming',
  'outside-guest': 'Direct',
  'webtext': 'WebChat',
  'gbm': 'Google Message',
  'staff': 'Staff',
}

export const guestListLabelByCategory = {
  'guests': 'Checked In',
  'past-guests': 'Checked Out',
  'upcoming-guests': 'Upcoming',
  'outside-guests': 'Direct Messages',
  'webtext': 'WebChat',
  'gbm': 'Google Messages',
  'staff': 'Staff',
}

export function getSearchGuestLabelByGuest(guest) {
  if (guest.is_staff) return 'Staff';
  if (guest.source === 'webtext') return 'WebChat';
  if (guest.source === 'bm') return 'Google Message';
  if (!guest.transaction_type) return 'Direct Message';
  if (guest.transaction_type === 'CheckIn') return 'Checked In';
  if (guest.transaction_type === 'CheckOut') return 'Checked Out';
  if (guest.transaction_type === 'Reservation') return 'Upcoming Guest';
  if (guest.transaction_type === 'Booking') return 'Upcoming Guest';
  if (guest.transaction_type === 'Cancel') return 'Cancelled';
  return '';
}

const emailForSmsOptions = [];
emailForSmsOptions.push({ label: 'Always (along with sms)', value: 'always' });
emailForSmsOptions.push({ label: 'Send on email as well when contact number is invalid / international', value: 'contact-invalid' });
emailForSmsOptions.push({ label: 'Send only on email (no sms) when contact number is invalid / international', value: 'contact-invalid-no-sms' });
emailForSmsOptions.push({ label: 'Do not send', value: 'do-not-send' });
export const EMAIL_FOR_SMS_OPTIONS = emailForSmsOptions;

const guestListDisplaySizeOptionsMap = {};
guestListDisplaySizeOptionsMap['50'] = '50 rows';
guestListDisplaySizeOptionsMap['100'] = '100 rows';
guestListDisplaySizeOptionsMap['all'] = 'All rows';
guestListDisplaySizeOptionsMap['past-7-days'] = 'Past 7 days';
guestListDisplaySizeOptionsMap['past-15-days'] = 'Past 15 days';
guestListDisplaySizeOptionsMap['past-30-days'] = 'Past 30 days';
guestListDisplaySizeOptionsMap['next-7-days'] = 'Next 7 days';
guestListDisplaySizeOptionsMap['next-15-days'] = 'Next 15 days';
guestListDisplaySizeOptionsMap['next-30-days'] = 'Next 30 days';
export const GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP = guestListDisplaySizeOptionsMap;

const guestListCheckedInDisplaySizeOptions = [];
guestListCheckedInDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['50'], value: '50' });
guestListCheckedInDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['100'], value: '100' });
export const GUEST_LIST_CHECKED_IN_DISPLAY_SIZE_OPTIONS = guestListCheckedInDisplaySizeOptions;

const guestListCheckedOutDisplaySizeOptions = [];
guestListCheckedOutDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['50'], value: '50' });
guestListCheckedOutDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['100'], value: '100' });
guestListCheckedOutDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['past-7-days'], value: 'past-7-days' });
guestListCheckedOutDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['past-15-days'], value: 'past-15-days' });
guestListCheckedOutDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['past-30-days'], value: 'past-30-days' });
export const GUEST_LIST_CHECKED_OUT_DISPLAY_SIZE_OPTIONS = guestListCheckedOutDisplaySizeOptions;


const guestListUpcomingDisplaySizeOptions = [];
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['50'], value: '50' });
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['100'], value: '100' });
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['all'], value: 'all' });
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['next-7-days'], value: 'next-7-days' });
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['next-15-days'], value: 'next-15-days' });
guestListUpcomingDisplaySizeOptions.push({ label: GUEST_LIST_DISPLAY_SIZE_OPTIONS_MAP['next-30-days'], value: 'next-30-days' });
export const GUEST_LIST_UPCOMING_DISPLAY_SIZE_OPTIONS = guestListUpcomingDisplaySizeOptions;


export const MSGTYPE_TO_STR = {
  'bookingMessage': 'booking_msg',
  'preArrivalMessage': 'pre_arrival_msg',
  'welcomeMessage': 'welcome_msg',
  'repeatWelcomeMessage': 'repeat_welcome_msg',
  'departureMessage': 'departure_msg',
  'automatedNonBusinessHoursMessage': 'starter', // 'automated_non_business_hours_msg'
  'webchatWhatsappStarterMessage': 'webchat_starter_msg',
  'webchatWhatsappNbhStarterMessage': 'webchat_non_business_hours_starter_msg',
};
export const MSGTYPE_TO_LABEL = {
  'preArrivalMessage': 'Pre-Arrival Message',
  'welcomeMessage': 'Check-In Welcome Message',
  'repeatWelcomeMessage': 'Check-In Welcome Message for returning guests',
  'departureMessage': 'Check-Out Message',
  'automatedNonBusinessHoursMessage': 'Automated Non Business Hours Message',
  'webchatWhatsappStarterMessage': 'Webchat WhatsApp Starter Message',
  'webchatWhatsappNbhStarterMessage': 'Webchat WhatsApp Non Buiness Hours Starter Message',
};
export const MSGTYPE_STR_TO_MSGTYPE = {
  'pre_arrival_msg': 'preArrivalMessage',
  'welcome_msg': 'welcomeMessage',
  'repeat_welcome_msg': 'repeatWelcomeMessage',
  'departure_msg': 'departureMessage',
  'starter': 'automatedNonBusinessHoursMessage',
  'webchat_starter_msg': 'webchatWhatsappStarterMessage',
  'webchat_non_business_hours_starter_msg': 'webchatWhatsappNbhStarterMessage',
};

const labelColorsLocal = [];
labelColorsLocal.push('#d8e1bb');
labelColorsLocal.push('#c8d3fc');
labelColorsLocal.push('#beebdf');
labelColorsLocal.push('#f7d0b8');
labelColorsLocal.push('#c4e2f2');
labelColorsLocal.push('#ffd1d5');
labelColorsLocal.push('#f4edc8');

export const LABEL_COLORS = labelColorsLocal;

export const LABEL_MAX_LENGTH = 20;

export const genericItemTypeToKeyName = {
  'messageTemplate': 'message_templates',
  'label': 'labels',
  'room': 'rooms',
  'rate_code': 'rate_codes',
  'booking_source': 'booking_sources',
  'sender_name': 'sender_names',
  'sender_email': 'sender_emails',
  'wc_rule': 'rules',
  'dg_page': 'pages',
  'dg_room': 'rooms',
  'response_template': 'response_templates',
  'response_template_category': 'response_template_categories',
  'prompt_template': 'prompt_templates',
  'prompt_template_category': 'prompt_template_categories',
  'email_template': 'email_templates',
  'transaction_email': 'transaction_emails',
  'rw_rule': 'rules',
  'feedback_text_template': 'feedback_text_templates',
  'comp_set_location': 'comp_set_locations',
};

export const promptKeyByType = {
  'regular': 'prompt_templates',
  'followup': 'followup_prompt_templates',
}

export const sortList = (list, keyValue, guestType = '') => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('sortList list: ', list);
    console.log('sortList keyValue: ', keyValue);
    console.log('sortList moment: ', moment().format());
  }
  if (guestType === 'upcoming-guest') {
    let guestsForToday = [], guestsForFuture = [], guestsFromPast = [];
    list.forEach((guestId) => {
      const guest = keyValue[guestId];
      if (process.env.NODE_ENV !== 'production') {
        console.log('sortList guest: ', guest._id, ' # check_in_date_local_str', guest.check_in_date_local_str, ' # ', moment(guest.check_in_date_local_str).format());
      }
      let check_in_dt = guest.check_in_date_local_str || guest.check_in_date_str || guest.check_in_date;
      if (moment(check_in_dt).isSame(moment(), 'day')) {
        guestsForToday.push(guestId);
      } else {
        if (moment(check_in_dt) > moment().endOf('day')) {
          guestsForFuture.push(guestId);
        } else {
          guestsFromPast.push(guestId);
        }
      }
    });
    return [...guestsForToday, ...guestsForFuture, ...guestsFromPast];
  }
  const temp_list = list.sort((a, b) => {
    if (!keyValue[a] && keyValue[b]) { return -1; }
    if (keyValue[a] && !keyValue[b]) { return 1; }
    if (!keyValue[a] && !keyValue[b]) { return 0; }
    if (guestType === 'upcoming-guest') {
      const date_a = keyValue[a].check_in_date;
      const date_b = keyValue[b].check_in_date;
      return new Date(date_a).getTime() - new Date(date_b).getTime();
    }
    let date_a = keyValue[a].date_last_message_received || keyValue[a].date_last_message_sent || keyValue[a].date_last_message;
    let date_b = keyValue[b].date_last_message_received || keyValue[b].date_last_message_sent || keyValue[b].date_last_message;
    if (!date_a && date_b) { return 1; }
    if (date_a && !date_b) { return -1; }
    // if (!date_a && !date_b) { return 0; }
    if (!date_a && !date_b) {
      date_a = keyValue[a].check_in_date;
      date_b = keyValue[b].check_in_date;
    }
    return new Date(date_b).getTime() - new Date(date_a).getTime();
  });
  return temp_list;
};

export function getRoomLabel(room) {
  if (!room || !room.attr) return '';
  if (room.attr['room-number']) {
    return `${room.label} - Room Number(${room.attr['room-number']})`;
  }
  if (room.attr['room-type']) {
    return `${room.label} - Room Type(${room.attr['room-type']})`;
  }
  return '';
}

export function getFullNameForDisplay(guest) {
  let result = '';
  if (guest.name) {
    return guest.name;
  }
  result = guest.first_name;
  if (guest.last_name) { result = `${result} ${guest.last_name}` }
  return result;
}

export function getAvataarName(guest) {
  let result = '';
  if (!guest) return result;
  if (guest.first_name && guest.first_name.toString()) { result = guest.first_name.toString().substring(0, 2); }
  if (!result) { if (guest.name) { result = guest.name.substring(0, 2); } }
  if (!result) { if (guest.contact) { result = guest.contact.replace(/\D/g, '').substring(0, 2); } }
  return result;
}

export function getNameWithContact(guest) {
  let result = '';
  if (guest.name) {
    return guest.name;
  }
  result = guest.first_name;
  if (guest.last_name) { result = `${result}, ${guest.last_name}` }
  if (!result) result = guest.contact;
  return result;
}

export const isContactValid = (countryCode, contactNumber) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('isContactValid countryCode: ', countryCode);
    console.log('isContactValid contactNumber: ', contactNumber, ' # ', contactNumber.length);
  }
  // if (countryCode === '39' ||
  //   countryCode === '33' ||
  //   countryCode === '44' ||
  //   countryCode === '61'
  // ) {
  //   if (contactNumber && contactNumber.length >= 9) { return true; }
  //   return false;
  // }
  if (countryCode && contactNumber) {
    if (countryCode.length + contactNumber.length >= 10) {
      return true;
    }
  }
  if (contactNumber && contactNumber.length >= 9) { return true; }
  return false;
};

export function contactDisplay(contact, user_info, hide_contact) {
  try {
    let temp = contact.replace(/\D/g, '');
    let result = formatPhoneNumberIntl(`+${temp}`);
    if (process.env.NODE_ENV !== 'production') {
      // console.log('contactDisplay contact: ', contact);
      // console.log('contactDisplay isValidPhoneNumber: ', isValidPhoneNumber(contact));
      // console.log('contactDisplay result: ', result);
    }
    if (contact.replace(/\D/g, '') !== result.replace(/\D/g, '')) {
      result = contact;
    }
    if (hide_contact) {
      if (contact && (!user_info || !user_info.profile || !user_info.profile.access_level ||
        user_info.profile.access_level !== ACCESS_LEVELS_LOCAL.GT_ADMIN
      )) {
        try {
          result = result.slice(0, 7) + [...Array(result.length - 9).keys()].map(i => 'X').toString().split(',').join('') + ' ' + result.slice(12);
        } catch (e) {
          result = result.slice(0, 7) + 'XXXXXX';
        }
      }
    }
    return result;
  } catch (e) {
    return contact;
  }
}

export function isEmojiOnlyMessage(text) {
  let result = false;
  let emojiOnlyMsg = '';
  try {
    const regex = emojiRegex();
    let match;
    let codePointsSum = 0;
    while (match = regex.exec(text)) {
      const emoji = match[0];
      codePointsSum += [...emoji].length;
    }
    if (codePointsSum * 2 === text.length) {
      emojiOnlyMsg = text;
      // text = '';
    }
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('error in emoji check: ', e);
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('emojiOnlyMsg: ', emojiOnlyMsg);
  }
  if (emojiOnlyMsg) { result = true; }
  return result;
}

export const isBMImageUrl = (msg) => {
  return msg.indexOf('https://storage.googleapis.com') === 0;
};

export async function replaceDynamicTerms(template, guest, guestType, roomInfo, settings = null) {
  let result = template;
  // if (!guest) return result;
  if (result.indexOf('{{firstname}}') > -1) {
    if (guest.first_name) {
      result = replaceAllLocal(result, '{{firstname}}', guest.first_name);
    } else {
      if (guest.name) {
        result = replaceAllLocal(result, '{{firstname}}', guest.name);
      } else {
        result = replaceAllLocal(result, '{{firstname}}', '');
      }
    }
  }
  if (result.indexOf('{{lastname}}') > -1) {
    if (guest.last_name) {
      result = replaceAllLocal(result, '{{lastname}}', guest.last_name);
    } else {
      result = replaceAllLocal(result, '{{lastname}}', '');
    }
  }
  if (result.indexOf('{{room_number}}') > -1) {
    if (guest.room_number) {
      result = replaceAllLocal(result, '{{room_number}}', guest.room_number);
    } else {
      result = replaceAllLocal(result, '{{room_number}}', '');
    }
  }
  if (result.indexOf('{{wifi}}') > -1) {
    if (settings && settings.engage && settings.engage.wifi_code) {
      result = replaceAllLocal(result, '{{wifi}}', settings.engage.wifi_code);
    } else {
      result = replaceAllLocal(result, '{{wifi}}', '');
    }
  }
  if (result.indexOf('{{keycode}}') > -1) {
    let keyCode = '';
    if (settings && settings.info && settings.info.has_remote_lock && settings.info.is_remote_lock_enabled) {
      if (guest.keycode) keyCode = guest.keycode;
    } else {
      // for room keycode from roominfo
      if (roomInfo && roomInfo.keycode) {
        keyCode = roomInfo.keycode;
      }
    }
    if (keyCode) {
      result = replaceAllLocal(result, '{{keycode}}', keyCode);
    } else {
      result = replaceAllLocal(result, '{{keycode}}', '');
    }
  }
  if (result.indexOf('{{reviewlink}}') > -1) {
    if (guest && guest.check_out_feedback_email && guest.check_out_feedback_email.short_url) {
      result = replaceAllLocal(result, '{{reviewlink}}', guest.check_out_feedback_email.short_url);
    } else {
      if (settings && settings.engage && settings.engage.default_review_link) {
        result = replaceAllLocal(result, '{{reviewlink}}', settings.engage.default_review_link);
      } else {
        result = replaceAllLocal(result, '{{reviewlink}}', '');
      }
    }
  }
  if (result.indexOf('{{hotelname}}') > -1) {
    if (settings && settings.info && settings.info.name) {
      result = replaceAllLocal(result, '{{hotelname}}', settings.info.name);
    } else {
      result = replaceAllLocal(result, '{{hotelname}}', '');
    }
  }
  if (result.indexOf('{{selfcheckinlink}}') > -1) {
    if (guest.self_service_url) {
      result = replaceAllLocal(result, '{{selfcheckinlink}}', guest.self_service_url);
    } else {
      result = replaceAllLocal(result, '{{selfcheckinlink}}', '');
    }
  }
  if (settings && settings.info && settings.info.has_stayhq) {
    if (result.indexOf('{{guestengagewebapplink}}') > -1) {
      let newLink = '';
      try {
        newLink = await getStayHqLink({
          location__id: 'wZYeknJ4QgyGXj7Jm',
          // link: 'something.com',
          type: 'engage',
          source: 'message_template',
          guest__id: guest._id,
          guest_type: guestType,
        });
        console.log('newLink: ', newLink);
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('newLink e: ', e);
        }
      }
      result = replaceAllLocal(result, '{{guestengagewebapplink}}', newLink);
    }
  }

  return result;
}


export const LAUNCHER_POSITIONS = {
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
};
export const LAUNCHER_POSITION_LABELS = {
  BOTTOM_RIGHT: 'Bottom Right',
  BOTTOM_LEFT: 'Bottom Left',
};
export const LAUNCHER_POSITION_KEY_TO_LABELS = {
  'bottom-right': 'Bottom Right',
  'bottom-left': 'Bottom Left',
};

const RULE_TYPES_LOCAL = {
  CUSTOM_PROMPT: 'custom-prompt',
  SHOW_AFTER_DELAY: 'show-after-delay',
  SHOW_AFTER_SCROLL: 'show-after-scroll',
  HIDE: 'hide',
};
export const RULE_TYPES = {
  ...RULE_TYPES_LOCAL,
};
export const RULE_TYPE_LABELS = {
  CUSTOM_PROMPT: 'Customize Prompt',
  SHOW_AFTER_DELAY: 'Show after a delay',
  SHOW_AFTER_SCROLL: 'Show after some scroll',
  HIDE: 'Do not show',
};
export const ruleTypeToLabel = {
  'custom-prompt': 'Customize Prompt',
  'show-after-delay': 'Show after a delay',
  'show-after-scroll': 'Show after some scroll',
  'hide': 'Do not show',
};

/* Broadcast */

export const broadcastTypeToLabel = {
  'one-time': 'One Time',
  'recurring': 'Recurring',
};
export const broadcastTargetToLabel = {
  'upcoming': 'Yet to arrive',
  'checked-in': 'In-House',
  'departing-soon': 'Departing Soon',
  'staff': 'Staff',
};
export const broadcastTargetDaysAheadToLabel = {
  'same-day': 'Same Day',
  'next-day': 'Next Day',
  '1-day-later': '1 day later',
  '1-day-later': '1 day later',
  '2-days-later': '2 days later',
  '3-days-later': '3 days later',
  '7-days-later': '7 days later',
  '10-days-later': '10 days later',
  '30-days-later': '30 days later',
};
export const broadcastTargetDaysSinceCheckInToLabel = {
  '1-day': '1 Day',
  '2-day': '2 Days',
  '3-day': '3 Days',
};
export const broadcastWeekDayToLabel = {
  'monday': 'Mon',
  'tuesday': 'Tue',
  'wednesday': 'Web',
  'thursday': 'Thu',
  'friday': 'Fri',
  'saturday': 'Sat',
  'sunday': 'Sun',
};


const lastMonthOptions = [];
lastMonthOptions.push({ label: 'Select', value: 'select' });
lastMonthOptions.push({ label: 'Last 7 days', value: 'last-7-days' });
lastMonthOptions.push({ label: 'Last 15 days', value: 'last-15-days' });
lastMonthOptions.push({ label: 'Last 1 Month', value: 'last-1-month' });
lastMonthOptions.push({ label: 'Last 3 Months', value: 'last-3-months' });
lastMonthOptions.push({ label: 'Last 6 Months', value: 'last-6-months' });
lastMonthOptions.push({ label: 'Last 12 Months', value: 'last-12-months' });
export const DATE_DURATION_OPTIONS = lastMonthOptions;


const otaOrder = [];
otaOrder.push('tripadvisor');
otaOrder.push('booking');
otaOrder.push('expedia');
otaOrder.push('google');
otaOrder.push('hotelscom');
otaOrder.push('hostelworld');
otaOrder.push('agoda');
otaOrder.push('mmt');
otaOrder.push('airbnb');
otaOrder.push('vrbo');
otaOrder.push('holidaycheck');
otaOrder.push('yelp');
otaOrder.push('opentable');
export const OTA_ORDER = otaOrder;

const OTA_NAME_TO_SOURCE_MAP_LOCAL = {
  'all': 'all',
  tripadvisor: 2,
  booking: 3,
  expedia: 4,
  google: 5,
  hotelscom: 6,
  agoda: 14,
  airbnb: 15,
  vrbo: 16,
  holidaycheck: 17,
  yelp: 18,
  opentable: 19,
};
export const OTA_NAME_TO_SOURCE_MAP = OTA_NAME_TO_SOURCE_MAP_LOCAL;

const OTA_ID_TO_NAME_FORMAL_MAP_LOCAL = {
  'tripadvisor': 'Tripadvisor',
  'booking': 'Booking',
  'expedia': 'Expedia',
  'google': 'Google',
  'hotelscom': 'Hotels.com',
  'airbnb': 'Airbnb',
  'agoda': 'Agoda',
  'vrbo': 'Vrbo',
  'holidaycheck': 'HolidayCheck',
  'yelp': 'Yelp',
  'opentable': 'Opentable',
};
export const OTA_ID_TO_NAME_FORMAL_MAP = OTA_ID_TO_NAME_FORMAL_MAP_LOCAL;

const OTA_SOURCE_TO_NAME_FORMAL_MAP_LOCAL = {
  'all': 'all',
  2: 'Tripadvisor',
  3: 'Booking',
  4: 'Expedia',
  5: 'Google',
  6: 'Hotels.com',
  14: 'Agoda',
  15: 'Airbnb',
  16: 'Vrbo',
  17: 'HolidayCheck',
  18: 'Yelp',
  19: 'Opentable',
};
export const OTA_SOURCE_TO_NAME_FORMAL_MAP = OTA_SOURCE_TO_NAME_FORMAL_MAP_LOCAL;

const OTA_SOURCE_TO_NAME_MAP_LOCAL = {
  'all': 'all',
  2: 'tripadvisor',
  3: 'booking',
  4: 'expedia',
  5: 'google',
  6: 'hotelscom',
  14: 'agoda',
  15: 'airbnb',
  16: 'vrbo',
  17: 'holidaycheck',
  18: 'yelp',
  19: 'opentable',
};
export const OTA_SOURCE_TO_NAME_MAP = OTA_SOURCE_TO_NAME_MAP_LOCAL;

export function renderOTALogo(source) {
  switch (source) {
    case 2:
      //return ( <img className="logo" src={TripadvisorLogo} /> )
      return (<img className="logo" src={gtconfig.TRIPADVISOR_LOGO} />)
    case 3:
      return (<img className="logo" src={gtconfig.BOOKING_LOGO} />)
    case 4:
      return (<img className="logo" src={gtconfig.EXPEDIA_LOGO} />)
    case 5:
      return (<img className="logo" src={gtconfig.GOOGLE_LOGO} />)
    case 6:
      return (<img className="logo" src={gtconfig.HOTELSCOM_LOGO} />)
    case 11:
      return (<img className="logo" src={gtconfig.HOSTELWORLD_LOGO} />)
    case 12:
      return (<img className="logo" src={gtconfig.MMT_LOGO} />)
    case 14:
      return (<img className="logo" src={gtconfig.AGODA_LOGO} />)
    case 15:
      return (<img className="logo" src={gtconfig.AIRBNB_LOGO} />)
    case 16:
      return (<img className="logo" src={gtconfig.VRBO_LOGO} />)
    case 17:
      return (<img className="logo" src={gtconfig.HOLIDAYCHECK_LOGO} />)
    case 18:
      return (<img className="logo" src={gtconfig.YELP_LOGO} />)
    case 19:
      return (<img className="logo" src={gtconfig.OPENTABLE_LOGO} />)
    default:
      return null;
  }
}

const RS = {
  RS_REPLIED: 'replied',
  RS_PENDING: 'pending',
  RS_HAS_MANAGER_RESPONSE: 'has_manager_response',
  RS_WAITING_FOR_APPROVAL: 'waiting_for_approval',
  RS_APPROVAL_RECEIVED: 'approval_received',
  RS_NEED_MORE_INFO: 'need_more_info',
  RS_MORE_INFO_RECEIVED: 'more_info_received',
  RS_NOT_YET_RESPONDED: 'not_yet_responded',
  RS_NOT_RESPONDABLE: 'not_respondable',
  RS_RESPONSE_POSTED: 'response_posted',
  RS_SELF_RESPONSE: 'self_response',
  RS_SELF_SUBMITTED_TO_GT: 'self_submitted_to_gt',
  RS_SELF_RESPONSE_REQUIRED: 'response_required',
};

export { RS };

const isRespondableLocal = (review) => {
  if (review.source === 3) { // Booking
    return (review.comment || review.comment_negative || review.comment_pro || review.comment_location);
  }
  return true;
};

export function isRespondable(review) {
  return isRespondableLocal(review);
}

export function getReviewState(review, selfResponse = false) {
  // let result = null;
  // return RS.RS_SELF_RESPONSE; // nkcheck
  if (review.has_manager_response) {
    return RS.RS_HAS_MANAGER_RESPONSE;
  }
  if (selfResponse) {
    if (review.self_response_posted
      ||
      review.response_posted_checkbox /* gtcheck - remove response_posted_checkbox */
    ) {
      return RS.RS_RESPONSE_POSTED;
    }
    if (review.submitted_to_gt) {
      return RS.RS_SELF_SUBMITTED_TO_GT;    
    }
    if (isRespondableLocal(review)) {
      return RS.RS_SELF_RESPONSE_REQUIRED;
    }
    return RS.RS_NOT_RESPONDABLE;
  }
  if (isRespondableLocal(review)) {
    if (review.response_posted_checkbox) {
      return RS.RS_RESPONSE_POSTED;
    }
    if (selfResponse) {
      return RS.RS_SELF_RESPONSE_REQUIRED;
    }
    if (review.is_sent_for_approval) {
      if (!review.approval_date) {
        return RS.RS_WAITING_FOR_APPROVAL;
      }
      return RS.RS_APPROVAL_RECEIVED;
    }
    if (review.is_sent_for_more_info) {
      if (!review.more_info_response) {
        return RS.RS_NEED_MORE_INFO;
      }
      return RS.RS_MORE_INFO_RECEIVED;
    }

    return RS.RS_NOT_YET_RESPONDED;
  }
  // if (selfResponse) {
  //   return RS.RS_SELF_RESPONSE;
  // }
  return RS.RS_NOT_RESPONDABLE;
}

export function mapReviewStateToString(reviewState) {
  switch (reviewState) {
    case RS.RS_NOT_YET_RESPONDED:
      return 'Preparing Response';
    case RS.RS_WAITING_FOR_APPROVAL:
      return 'Approval Pending';
    case RS.RS_APPROVAL_RECEIVED:
      return 'Approval Received';
    case RS.RS_NEED_MORE_INFO:
      return 'Need More Info';
    case RS.RS_MORE_INFO_RECEIVED:
      return 'Info Received';
    case RS.RS_SELF_SUBMITTED_TO_GT:
      return 'Submitted to GuestTouch';
    case RS.RS_RESPONSE_POSTED:
      return 'Response Posted';
    case RS.RS_HAS_MANAGER_RESPONSE:
      return 'Response Posted';
    case RS.RS_NOT_RESPONDABLE:
      return 'Response Not Required';
    case RS.RS_SELF_RESPONSE:
      return 'Self Response';
    case RS.RS_SELF_RESPONSE_REQUIRED:
      return 'Response Required';
    case RS.RS_REPLIED:
      return 'Replied';
    case RS.RS_PENDING:
      return 'Pending';
    default:
      return null;
  }
}

const REVIEW_TABS_MAP_LOCAL = {
  'all': true,
  'response-required': true,
  'approval': true,
  'need-info': true,
  'posted': true,
  'replied': true,
  'pending': true,
};
export const REVIEW_TABS_MAP = REVIEW_TABS_MAP_LOCAL;

const REVIEW_TABS_TO_RS_MAP_LOCAL = {
  'all': 'all',
  'response-required': 'response_required',
  'approval': 'waiting_for_approval',
  'need-info': 'need_more_info',
  'posted': 'response_posted',
  'submitted-to-gt': 'self_submitted_to_gt',
  'replied': 'replied',
  'pending': 'pending',
};
export const REVIEW_TABS_TO_RS_MAP = REVIEW_TABS_TO_RS_MAP_LOCAL;

const REVIEW_STATE_TO_CTA_LABEL_LOCAL = {
  'approvalPending': 'Approve Now',
  'needInfo': 'Provide Info Now',
  'responsePending': 'Respond Now',
};
export const REVIEW_STATE_TO_CTA_LABEL = REVIEW_STATE_TO_CTA_LABEL_LOCAL;

const REVIEW_STATE_TO_CTA_PENDING_LABEL_LOCAL = {
  'approvalPending': 'Approval Pending',
  'needInfo': 'Need Info Pending',
  'responsePending': 'Response Pending',
};
export const REVIEW_STATE_TO_CTA_PENDING_LABEL = REVIEW_STATE_TO_CTA_PENDING_LABEL_LOCAL;

const RATING_TO_STRING_MAP_LOCAL = {
  'all': 'all',
  5: '5 Stars',
  4: '4 Stars',
  3: '3 Stars',
  2: '2 Stars',
  1: '1 Stars',
};
export const RATING_TO_STRING_MAP = RATING_TO_STRING_MAP_LOCAL;

export function ratingToInt(rating) {
  if (rating < 1) return 1;
  return Math.floor(rating);
}

export function isRatingAGreaterThanB(a, b) {
  if (a && b && a >= b) return true;
  if (a && !b) return true;
  return false;
}


const PN_TO_ID_LOCAL = {
  positive: true,
  negative: true,
};
export const PN_TO_ID = PN_TO_ID_LOCAL;

const FEEDBACK_SOURCE_TO_LABEL_LOCAL = {
  automated: 'Automated',
  upload: 'Upload',
  instant_invite: 'Instant Invite',
  direct: 'Direct',
};
export const FEEDBACK_SOURCE_TO_LABEL = FEEDBACK_SOURCE_TO_LABEL_LOCAL;


const guestListSortOptions = [];
guestListSortOptions.push({ label: 'Newest First', value: 'newest' });
guestListSortOptions.push({ label: 'Oldest First', value: 'oldest' });
export const GUEST_LIST_SORT_OPTIONS = guestListSortOptions;

const guestListSortOptionsMap = {};
guestListSortOptionsMap['newest'] = 'Newest First';
guestListSortOptionsMap['oldest'] = 'Oldest First';
export const GUEST_LIST_SORT_OPTIONS_MAP = guestListSortOptionsMap;


const pageSizeOptions = [];
pageSizeOptions.push({ label: '10', value: 10 });
pageSizeOptions.push({ label: '25', value: 25 });
pageSizeOptions.push({ label: '50', value: 50 });
pageSizeOptions.push({ label: '75', value: 75 });
pageSizeOptions.push({ label: '100', value: 100 });
export const PAGE_SIZE_OPTIONS = pageSizeOptions;

export const DEVICE_TYPE_CODE_TO_LABEL = {
  'desktop': 'Desktop',
  'mobile': 'Mobile',
  'tablet': 'Tablet',
};


export function changeInValue(a, b) {
  let result = '';
  result = (a - b).toFixed(1);
  if (result > 0) {
    return (
      <span className="pos"><i class="fa-solid fa-arrow-up"></i>{result}</span>
    )
  }
  if (result < 0) {
    return (
      <span className="neg"><i class="fa-solid fa-arrow-down"></i>{result}</span>
    )
  }
  return (
    <span className="">-</span>
  )
}

export function changeInValuePercent(a, b) {
  if (!b) return (<span className="">-</span>);

  let result = '';
  // result = (a - b).toFixed(1);
  result = (((a - b)*100)/b).toFixed(1);
  
  if (result > 0) {
    return (
      <span className="pos"><i class="fa-solid fa-arrow-up"></i>{result}%</span>
    )
  }
  if (result < 0) {
    return (
      <span className="neg"><i class="fa-solid fa-arrow-down"></i>{result}%</span>
    )
  }
  return (
    <span className="">-</span>
  )
}


async function getStayHqLink(params) {
  return new Promise((resolve, reject) => {
    auth.currentUser
      .getIdToken(false)
      .then((idToken) => {
        const url = gtconfig.DATA_BACKEND_URL;
        if (process.env.NODE_ENV !== 'production') {
          console.log('getStayHqLink: ', params);
        }
        const opdata = {};
        opdata.params = {
          ...params,
          relogin_identifier: window.gtCId,
        };
        const postData = {
          appId: gtconfig.APP_ID,
          idToken,
          op: 'get_stayhq_link', // op, // 'get_guest_list',
          opdata,
        };
        if (process.env.NODE_ENV !== 'production') {
          console.log(`getStayHqLink postData: `, postData);
        }

        const headers = {
          'Content-type': 'application/json',
          'x-api-key': gtconfig.DATA_BACKEND_URL_API_KEY,
        };
        const axiosData = {
          gtps: postData,
          M6Usp3JlX8gWCInDK3OX: 'Pk7giCRsG75yy8OcVHcN',
        };

        axios
          .post(url, axiosData, { headers })
          .then((response) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getStayHqLink response: `, response.data);
            }
            const { status } = response.data;
            const { data } = response.data;
            if (status === 'success' && data && data.link) {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`getStayHqLink data: `, data);
              }
              resolve(data.link);
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`getStayHqLink error: `, response);
              }
              sendEmailAlertLocal({
                app: gtconfig.APP_NAME,
                subject: `${gtconfig.APP_NAME} - getStayHqLink Error - axios - ${auth.currentUser.uid}`,
                message: JSON.stringify({ url, postData }),
                // error: JSON.stringify({ err , config: err.config }),
                error: `link missing`,
              });
              reject('error');
            }
          })
          .catch((err) => {
            // axios error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getStayHqLink err--------------------: `, err);
              console.log(`getStayHqLink err.response--------------------: `, err.response);
            }
            reject(err);
          })
          .catch((err) => {
            // firebase error
            if (process.env.NODE_ENV !== 'production') {
              console.log(`getStayHqLink firebase err--------------------: ${err}`);
            }
            reject(err);
          });
      });
  });
}


function sendEmailAlertLocal(params) {
  const url = gtconfig.EMAIL_ALERT_URL;
  const opdata = {};
  opdata.params = params;
  const postData = {
    op: 'send_email_alert',
    opdata,
  };
  if (process.env.NODE_ENV !== 'production') {
    console.log('postData: ', postData);
  }

  const headers = {
    'Content-type': 'application/json',
    'x-api-key': gtconfig.EMAIL_ALERT_API_KEY,
  };
  const axiosData = {
    // nkcheck - needed
    gtp: CryptoJS.AES.encrypt(JSON.stringify(postData), gtconfig.EMAIL_ALERT_URL_GT_BASIC_KEY).toString(),
    // gtp: JSON.stringify(postData),
  };

  axios.post(url, axiosData, { headers }).then((response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('sendEmailAlertLocal response: ', response.data);
    }
    const { status } = response.data;
    if (status !== 'success') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal success: ', response);
      }
    }
  })
    .catch((err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('sendEmailAlertLocal err--------------------: ', err);
      }
    });
}

export function sendEmailAlert(params) {
  sendEmailAlertLocal(params);
}



export const LANGUAGE_LIST_KEYS = {
  // 'ab': 'Abkhazian',
  'aa': 'Afar',
  'af': 'Afrikaans',
  // 'ak': 'Akan',
  'sq': 'Albanian',
  'am': 'Amharic',
  'ar': 'Arabic',
  // 'an': 'Aragonese',
  'hy': 'Armenian',
  // 'as': 'Assamese',
  // 'av': 'Avaric',
  // 'ae': 'Avestan',
  // 'ay': 'Aymara',
  'az': 'Azerbaijani',
  // 'bm': 'Bambara',
  // 'ba': 'Bashkir',
  'eu': 'Basque',
  'be': 'Belarusian',
  'bn': 'Bengali',
  // 'bh': 'Bihari',
  // 'bi': 'Bislama',
  'bs': 'Bosnian',
  // 'br': 'Breton',
  'bg': 'Bulgarian',
  'my': 'Burmese',
  'ca': 'Catalan',
  'km': 'Central Khmer',
  // 'ch': 'Chamorro',
  // 'ce': 'Chechen',
  'ny': 'Nyanja',
  'zh': 'Chinese',
  // 'cu': 'Church Slavonic,',
  // 'cv': 'Chuvash',
  // 'kw': 'Cornish',
  'co': 'Corsican',
  // 'cr': 'Cree',
  'hr': 'Croatian',
  'cs': 'Czech',
  'da': 'Danish',
  // 'dv': 'Divehi',
  'nl': 'Dutch',
  // 'dz': 'Dzongkha',
  'en': 'English',
  'eo': 'Esperanto',
  'et': 'Estonian',
  // 'ee': 'Ewe',
  // 'fo': 'Faroese',
  // 'fj': 'Fijian',
  'fi': 'Finnish',
  'fr': 'French',
  'ff': 'Fulah',
  'gd': 'Gaelic',
  'gl': 'Galician',
  // 'lg': 'Ganda',
  'ka': 'Georgian',
  'de': 'German',
  // 'ki': 'Gikuyu, Kikuyu',
  'el': 'Greek',
  // 'kl': 'Kalaallisut',
  // 'gn': 'Guarani',
  'gu': 'Gujarati',
  'ht': 'Haitian Creole',
  'ha': 'Hausa',
  'he': 'Hebrew',
  // 'hz': 'Herero',
  'hi': 'Hindi',
  // 'ho': 'Hiri Motu',
  'hu': 'Hungarian',
  'is': 'Icelandic',
  // 'io': 'Ido',
  'ig': 'Igbo',
  'id': 'Indonesian',
  // 'ia': 'Interlingua',
  // 'ie': 'Interlingue',
  // 'iu': 'Inuktitut',
  // 'ik': 'Inupiaq',
  'ga': 'Irish',
  'it': 'Italian',
  'ja': 'Japanese',
  'jv': 'Javanese',
  'kn': 'Kannada',
  // 'kr': 'Kanuri',
  // 'ks': 'Kashmiri',
  'kk': 'Kazakh',
  'rw': 'Kinyarwanda',
  // 'kv': 'Komi',
  // 'kg': 'Kongo',
  'ko': 'Korean',
  // 'kj': 'Kwanyama',
  'ku': 'Kurdish',
  'ky': 'Kyrgyz',
  'lo': 'Lao',
  'la': 'Latin',
  'lv': 'Latvian',
  'lb': 'Luxembourgish',
  // 'li': 'Limburgish',
  // 'ln': 'Lingala',
  'lt': 'Lithuanian',
  // 'lu': 'Luba-Katanga',
  'mk': 'Macedonian',
  'mg': 'Malagasy',
  'ms': 'Malay',
  'ml': 'Malayalam',
  'mt': 'Maltese',
  // 'gv': 'Manx',
  'mi': 'Maori',
  'mr': 'Marathi',
  // 'mh': 'Marshallese',
  'ro': 'Romanian',
  'mn': 'Mongolian',
  // 'na': 'Nauru',
  // 'nv': 'Navajo',
  // 'nd': 'Northern Ndebele',
  // 'ng': 'Ndonga',
  'ne': 'Nepali',
  // 'se': 'Northern Sami',
  'no': 'Norwegian',
  'nb': 'Norwegian Bokmål',
  'nn': 'Norwegian Nynorsk',
  // 'ii': 'Nuosu',
  // 'oc': 'Occitan',
  // 'oj': 'Ojibwa',
  'or': 'Oriya',
  // 'om': 'Oromo',
  // 'os': 'Ossetian',
  // 'pi': 'Pali',
  'pa': 'Punjabi',
  'ps': 'Pashto',
  'fa': 'Persian',
  'pl': 'Polish',
  'pt': 'Portuguese',
  // 'qu': 'Quechua',
  // 'rm': 'Romansh',
  // 'rn': 'Rundi',
  'ru': 'Russian',
  'sm': 'Samoan',
  // 'sg': 'Sango',
  // 'sa': 'Sanskrit',
  // 'sc': 'Sardinian',
  'sr': 'Serbian',
  'sn': 'Shona',
  'sd': 'Sindhi',
  'si': 'Sinhala',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'so': 'Somali',
  'st': 'Sotho, Southern',
  // 'nr': 'South Ndebele',
  'es': 'Spanish',
  'su': 'Sundanese',
  'sw': 'Swahili',
  // 'ss': 'Swati',
  'sv': 'Swedish',
  'tl': 'Tagalog',
  // 'ty': 'Tahitian',
  'tg': 'Tajik',
  'ta': 'Tamil',
  'tt': 'Tatar',
  'te': 'Telugu',
  'th': 'Thai',
  // 'bo': 'Tibetan',
  // 'ti': 'Tigrinya',
  // 'to': 'Tonga',
  // 'ts': 'Tsonga',
  // 'tn': 'Tswana',
  'tr': 'Turkish',
  'tk': 'Turkmen',
  // 'tw': 'Twi',
  'ug': 'Uighur',
  'uk': 'Ukrainian',
  'ur': 'Urdu',
  'uz': 'Uzbek',
  // 've': 'Venda',
  'vi': 'Vietnamese',
  // 'vo': 'Volap_k',
  // 'wa': 'Walloon',
  'cy': 'Welsh',
  'fy': 'Western Frisian',
  // 'wo': 'Wolof',
  'xh': 'Xhosa',
  'yi': 'Yiddish',
  'yo': 'Yoruba',
  // 'za': 'Zhuang',
  'zu': 'Zulu',
};

export const COUNTRY_LIST_KEYS = {
  'AF': 'Afghanistan',
  'AX': 'Aland Islands',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua And Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia',
  'BA': 'Bosnia And Herzegovina',
  'BW': 'Botswana',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'CV': 'Cape Verde',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CG': 'Congo',
  'CD': 'Congo, Democratic Republic',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': 'Cote D\'Ivoire',
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands (Malvinas)',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard Island & Mcdonald Islands',
  'VA': 'Holy See (Vatican City State)',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran, Islamic Republic Of',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IM': 'Isle Of Man',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KR': 'Korea',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': 'Lao People\'s Democratic Republic',
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libyan Arab Jamahiriya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macao',
  'MK': 'Macedonia',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia, Federated States Of',
  'MD': 'Moldova',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'AN': 'Netherlands Antilles',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestinian Territory, Occupied',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RU': 'Russian Federation',
  'RW': 'Rwanda',
  'BL': 'Saint Barthelemy',
  'SH': 'Saint Helena',
  'KN': 'Saint Kitts And Nevis',
  'LC': 'Saint Lucia',
  'MF': 'Saint Martin',
  'PM': 'Saint Pierre And Miquelon',
  'VC': 'Saint Vincent And Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome And Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia And Sandwich Isl.',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard And Jan Mayen',
  'SZ': 'Swaziland',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TW': 'Taiwan',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania',
  'TH': 'Thailand',
  'TL': 'Timor-Leste',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad And Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks And Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'US': 'United States',
  'UM': 'United States Outlying Islands',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VE': 'Venezuela',
  'VN': 'Viet Nam',
  'VG': 'Virgin Islands, British',
  'VI': 'Virgin Islands, U.S.',
  'WF': 'Wallis And Futuna',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe'
};

export const ISD_CODES = [
  {
    "name": "Afghanistan",
    "dial_code": "+93",
    "code": "AF"
  },
  {
    "name": "Aland Islands",
    "dial_code": "+358",
    "code": "AX"
  },
  {
    "name": "Albania",
    "dial_code": "+355",
    "code": "AL"
  },
  {
    "name": "Algeria",
    "dial_code": "+213",
    "code": "DZ"
  },
  {
    "name": "AmericanSamoa",
    "dial_code": "+1684",
    "code": "AS"
  },
  {
    "name": "Andorra",
    "dial_code": "+376",
    "code": "AD"
  },
  {
    "name": "Angola",
    "dial_code": "+244",
    "code": "AO"
  },
  {
    "name": "Anguilla",
    "dial_code": "+1264",
    "code": "AI"
  },
  {
    "name": "Antarctica",
    "dial_code": "+672",
    "code": "AQ"
  },
  {
    "name": "Antigua and Barbuda",
    "dial_code": "+1268",
    "code": "AG"
  },
  {
    "name": "Argentina",
    "dial_code": "+54",
    "code": "AR"
  },
  {
    "name": "Armenia",
    "dial_code": "+374",
    "code": "AM"
  },
  {
    "name": "Aruba",
    "dial_code": "+297",
    "code": "AW"
  },
  {
    "name": "Australia",
    "dial_code": "+61",
    "code": "AU"
  },
  {
    "name": "Austria",
    "dial_code": "+43",
    "code": "AT"
  },
  {
    "name": "Azerbaijan",
    "dial_code": "+994",
    "code": "AZ"
  },
  {
    "name": "Bahamas",
    "dial_code": "+1242",
    "code": "BS"
  },
  {
    "name": "Bahrain",
    "dial_code": "+973",
    "code": "BH"
  },
  {
    "name": "Bangladesh",
    "dial_code": "+880",
    "code": "BD"
  },
  {
    "name": "Barbados",
    "dial_code": "+1246",
    "code": "BB"
  },
  {
    "name": "Belarus",
    "dial_code": "+375",
    "code": "BY"
  },
  {
    "name": "Belgium",
    "dial_code": "+32",
    "code": "BE"
  },
  {
    "name": "Belize",
    "dial_code": "+501",
    "code": "BZ"
  },
  {
    "name": "Benin",
    "dial_code": "+229",
    "code": "BJ"
  },
  {
    "name": "Bermuda",
    "dial_code": "+1441",
    "code": "BM"
  },
  {
    "name": "Bhutan",
    "dial_code": "+975",
    "code": "BT"
  },
  {
    "name": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "code": "BO"
  },
  {
    "name": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "code": "BA"
  },
  {
    "name": "Botswana",
    "dial_code": "+267",
    "code": "BW"
  },
  {
    "name": "Brazil",
    "dial_code": "+55",
    "code": "BR"
  },
  {
    "name": "British Indian Ocean Territory",
    "dial_code": "+246",
    "code": "IO"
  },
  {
    "name": "Brunei Darussalam",
    "dial_code": "+673",
    "code": "BN"
  },
  {
    "name": "Bulgaria",
    "dial_code": "+359",
    "code": "BG"
  },
  {
    "name": "Burkina Faso",
    "dial_code": "+226",
    "code": "BF"
  },
  {
    "name": "Burundi",
    "dial_code": "+257",
    "code": "BI"
  },
  {
    "name": "Cambodia",
    "dial_code": "+855",
    "code": "KH"
  },
  {
    "name": "Cameroon",
    "dial_code": "+237",
    "code": "CM"
  },
  // {
  //   "name": "Canada",
  //   "dial_code": "+1",
  //   "code": "CA"
  // },
  {
    "name": "Cape Verde",
    "dial_code": "+238",
    "code": "CV"
  },
  {
    "name": "Cayman Islands",
    "dial_code": "+ 345",
    "code": "KY"
  },
  {
    "name": "Central African Republic",
    "dial_code": "+236",
    "code": "CF"
  },
  {
    "name": "Chad",
    "dial_code": "+235",
    "code": "TD"
  },
  {
    "name": "Chile",
    "dial_code": "+56",
    "code": "CL"
  },
  {
    "name": "China",
    "dial_code": "+86",
    "code": "CN"
  },
  {
    "name": "Christmas Island",
    "dial_code": "+61",
    "code": "CX"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "code": "CC"
  },
  {
    "name": "Colombia",
    "dial_code": "+57",
    "code": "CO"
  },
  {
    "name": "Comoros",
    "dial_code": "+269",
    "code": "KM"
  },
  {
    "name": "Congo",
    "dial_code": "+242",
    "code": "CG"
  },
  {
    "name": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "code": "CD"
  },
  {
    "name": "Cook Islands",
    "dial_code": "+682",
    "code": "CK"
  },
  {
    "name": "Costa Rica",
    "dial_code": "+506",
    "code": "CR"
  },
  {
    "name": "Cote d'Ivoire",
    "dial_code": "+225",
    "code": "CI"
  },
  {
    "name": "Croatia",
    "dial_code": "+385",
    "code": "HR"
  },
  {
    "name": "Cuba",
    "dial_code": "+53",
    "code": "CU"
  },
  {
    "name": "Cyprus",
    "dial_code": "+357",
    "code": "CY"
  },
  {
    "name": "Czech Republic",
    "dial_code": "+420",
    "code": "CZ"
  },
  {
    "name": "Denmark",
    "dial_code": "+45",
    "code": "DK"
  },
  {
    "name": "Djibouti",
    "dial_code": "+253",
    "code": "DJ"
  },
  {
    "name": "Dominica",
    "dial_code": "+1767",
    "code": "DM"
  },
  {
    "name": "Dominican Republic",
    "dial_code": "+1849",
    "code": "DO"
  },
  {
    "name": "Ecuador",
    "dial_code": "+593",
    "code": "EC"
  },
  {
    "name": "Egypt",
    "dial_code": "+20",
    "code": "EG"
  },
  {
    "name": "El Salvador",
    "dial_code": "+503",
    "code": "SV"
  },
  {
    "name": "Equatorial Guinea",
    "dial_code": "+240",
    "code": "GQ"
  },
  {
    "name": "Eritrea",
    "dial_code": "+291",
    "code": "ER"
  },
  {
    "name": "Estonia",
    "dial_code": "+372",
    "code": "EE"
  },
  {
    "name": "Ethiopia",
    "dial_code": "+251",
    "code": "ET"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "code": "FK"
  },
  {
    "name": "Faroe Islands",
    "dial_code": "+298",
    "code": "FO"
  },
  {
    "name": "Fiji",
    "dial_code": "+679",
    "code": "FJ"
  },
  {
    "name": "Finland",
    "dial_code": "+358",
    "code": "FI"
  },
  {
    "name": "France",
    "dial_code": "+33",
    "code": "FR"
  },
  {
    "name": "French Guiana",
    "dial_code": "+594",
    "code": "GF"
  },
  {
    "name": "French Polynesia",
    "dial_code": "+689",
    "code": "PF"
  },
  {
    "name": "Gabon",
    "dial_code": "+241",
    "code": "GA"
  },
  {
    "name": "Gambia",
    "dial_code": "+220",
    "code": "GM"
  },
  {
    "name": "Georgia",
    "dial_code": "+995",
    "code": "GE"
  },
  {
    "name": "Germany",
    "dial_code": "+49",
    "code": "DE"
  },
  {
    "name": "Ghana",
    "dial_code": "+233",
    "code": "GH"
  },
  {
    "name": "Gibraltar",
    "dial_code": "+350",
    "code": "GI"
  },
  {
    "name": "Greece",
    "dial_code": "+30",
    "code": "GR"
  },
  {
    "name": "Greenland",
    "dial_code": "+299",
    "code": "GL"
  },
  {
    "name": "Grenada",
    "dial_code": "+1473",
    "code": "GD"
  },
  {
    "name": "Guadeloupe",
    "dial_code": "+590",
    "code": "GP"
  },
  {
    "name": "Guam",
    "dial_code": "+1671",
    "code": "GU"
  },
  {
    "name": "Guatemala",
    "dial_code": "+502",
    "code": "GT"
  },
  {
    "name": "Guernsey",
    "dial_code": "+44",
    "code": "GG"
  },
  {
    "name": "Guinea",
    "dial_code": "+224",
    "code": "GN"
  },
  {
    "name": "Guinea-Bissau",
    "dial_code": "+245",
    "code": "GW"
  },
  {
    "name": "Guyana",
    "dial_code": "+595",
    "code": "GY"
  },
  {
    "name": "Haiti",
    "dial_code": "+509",
    "code": "HT"
  },
  {
    "name": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "code": "VA"
  },
  {
    "name": "Honduras",
    "dial_code": "+504",
    "code": "HN"
  },
  {
    "name": "Hong Kong",
    "dial_code": "+852",
    "code": "HK"
  },
  {
    "name": "Hungary",
    "dial_code": "+36",
    "code": "HU"
  },
  {
    "name": "Iceland",
    "dial_code": "+354",
    "code": "IS"
  },
  {
    "name": "India",
    "dial_code": "+91",
    "code": "IN"
  },
  {
    "name": "Indonesia",
    "dial_code": "+62",
    "code": "ID"
  },
  {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "code": "IR"
  },
  {
    "name": "Iraq",
    "dial_code": "+964",
    "code": "IQ"
  },
  {
    "name": "Ireland",
    "dial_code": "+353",
    "code": "IE"
  },
  {
    "name": "Isle of Man",
    "dial_code": "+44",
    "code": "IM"
  },
  {
    "name": "Israel",
    "dial_code": "+972",
    "code": "IL"
  },
  {
    "name": "Italy",
    "dial_code": "+39",
    "code": "IT"
  },
  {
    "name": "Jamaica",
    "dial_code": "+1876",
    "code": "JM"
  },
  {
    "name": "Japan",
    "dial_code": "+81",
    "code": "JP"
  },
  {
    "name": "Jersey",
    "dial_code": "+44",
    "code": "JE"
  },
  {
    "name": "Jordan",
    "dial_code": "+962",
    "code": "JO"
  },
  {
    "name": "Kazakhstan",
    "dial_code": "+77",
    "code": "KZ"
  },
  {
    "name": "Kenya",
    "dial_code": "+254",
    "code": "KE"
  },
  {
    "name": "Kiribati",
    "dial_code": "+686",
    "code": "KI"
  },
  {
    "name": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "code": "KP"
  },
  {
    "name": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "code": "KR"
  },
  {
    "name": "Kuwait",
    "dial_code": "+965",
    "code": "KW"
  },
  {
    "name": "Kyrgyzstan",
    "dial_code": "+996",
    "code": "KG"
  },
  {
    "name": "Laos",
    "dial_code": "+856",
    "code": "LA"
  },
  {
    "name": "Latvia",
    "dial_code": "+371",
    "code": "LV"
  },
  {
    "name": "Lebanon",
    "dial_code": "+961",
    "code": "LB"
  },
  {
    "name": "Lesotho",
    "dial_code": "+266",
    "code": "LS"
  },
  {
    "name": "Liberia",
    "dial_code": "+231",
    "code": "LR"
  },
  {
    "name": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "code": "LY"
  },
  {
    "name": "Liechtenstein",
    "dial_code": "+423",
    "code": "LI"
  },
  {
    "name": "Lithuania",
    "dial_code": "+370",
    "code": "LT"
  },
  {
    "name": "Luxembourg",
    "dial_code": "+352",
    "code": "LU"
  },
  {
    "name": "Macao",
    "dial_code": "+853",
    "code": "MO"
  },
  {
    "name": "Macedonia",
    "dial_code": "+389",
    "code": "MK"
  },
  {
    "name": "Madagascar",
    "dial_code": "+261",
    "code": "MG"
  },
  {
    "name": "Malawi",
    "dial_code": "+265",
    "code": "MW"
  },
  {
    "name": "Malaysia",
    "dial_code": "+60",
    "code": "MY"
  },
  {
    "name": "Maldives",
    "dial_code": "+960",
    "code": "MV"
  },
  {
    "name": "Mali",
    "dial_code": "+223",
    "code": "ML"
  },
  {
    "name": "Malta",
    "dial_code": "+356",
    "code": "MT"
  },
  {
    "name": "Marshall Islands",
    "dial_code": "+692",
    "code": "MH"
  },
  {
    "name": "Martinique",
    "dial_code": "+596",
    "code": "MQ"
  },
  {
    "name": "Mauritania",
    "dial_code": "+222",
    "code": "MR"
  },
  {
    "name": "Mauritius",
    "dial_code": "+230",
    "code": "MU"
  },
  {
    "name": "Mayotte",
    "dial_code": "+262",
    "code": "YT"
  },
  {
    "name": "Mexico",
    "dial_code": "+52",
    "code": "MX"
  },
  {
    "name": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "code": "FM"
  },
  {
    "name": "Moldova",
    "dial_code": "+373",
    "code": "MD"
  },
  {
    "name": "Monaco",
    "dial_code": "+377",
    "code": "MC"
  },
  {
    "name": "Mongolia",
    "dial_code": "+976",
    "code": "MN"
  },
  {
    "name": "Montenegro",
    "dial_code": "+382",
    "code": "ME"
  },
  {
    "name": "Montserrat",
    "dial_code": "+1664",
    "code": "MS"
  },
  {
    "name": "Morocco",
    "dial_code": "+212",
    "code": "MA"
  },
  {
    "name": "Mozambique",
    "dial_code": "+258",
    "code": "MZ"
  },
  {
    "name": "Myanmar",
    "dial_code": "+95",
    "code": "MM"
  },
  {
    "name": "Namibia",
    "dial_code": "+264",
    "code": "NA"
  },
  {
    "name": "Nauru",
    "dial_code": "+674",
    "code": "NR"
  },
  {
    "name": "Nepal",
    "dial_code": "+977",
    "code": "NP"
  },
  {
    "name": "Netherlands",
    "dial_code": "+31",
    "code": "NL"
  },
  {
    "name": "Netherlands Antilles",
    "dial_code": "+599",
    "code": "AN"
  },
  {
    "name": "New Caledonia",
    "dial_code": "+687",
    "code": "NC"
  },
  {
    "name": "New Zealand",
    "dial_code": "+64",
    "code": "NZ"
  },
  {
    "name": "Nicaragua",
    "dial_code": "+505",
    "code": "NI"
  },
  {
    "name": "Niger",
    "dial_code": "+227",
    "code": "NE"
  },
  {
    "name": "Nigeria",
    "dial_code": "+234",
    "code": "NG"
  },
  {
    "name": "Niue",
    "dial_code": "+683",
    "code": "NU"
  },
  {
    "name": "Norfolk Island",
    "dial_code": "+672",
    "code": "NF"
  },
  {
    "name": "Northern Mariana Islands",
    "dial_code": "+1670",
    "code": "MP"
  },
  {
    "name": "Norway",
    "dial_code": "+47",
    "code": "NO"
  },
  {
    "name": "Oman",
    "dial_code": "+968",
    "code": "OM"
  },
  {
    "name": "Pakistan",
    "dial_code": "+92",
    "code": "PK"
  },
  {
    "name": "Palau",
    "dial_code": "+680",
    "code": "PW"
  },
  {
    "name": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "code": "PS"
  },
  {
    "name": "Panama",
    "dial_code": "+507",
    "code": "PA"
  },
  {
    "name": "Papua New Guinea",
    "dial_code": "+675",
    "code": "PG"
  },
  {
    "name": "Paraguay",
    "dial_code": "+595",
    "code": "PY"
  },
  {
    "name": "Peru",
    "dial_code": "+51",
    "code": "PE"
  },
  {
    "name": "Philippines",
    "dial_code": "+63",
    "code": "PH"
  },
  {
    "name": "Pitcairn",
    "dial_code": "+872",
    "code": "PN"
  },
  {
    "name": "Poland",
    "dial_code": "+48",
    "code": "PL"
  },
  {
    "name": "Portugal",
    "dial_code": "+351",
    "code": "PT"
  },
  {
    "name": "Puerto Rico",
    "dial_code": "+1939",
    "code": "PR"
  },
  {
    "name": "Qatar",
    "dial_code": "+974",
    "code": "QA"
  },
  {
    "name": "Romania",
    "dial_code": "+40",
    "code": "RO"
  },
  {
    "name": "Russia",
    "dial_code": "+7",
    "code": "RU"
  },
  {
    "name": "Rwanda",
    "dial_code": "+250",
    "code": "RW"
  },
  {
    "name": "Reunion",
    "dial_code": "+262",
    "code": "RE"
  },
  {
    "name": "Saint Barthelemy",
    "dial_code": "+590",
    "code": "BL"
  },
  {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "code": "SH"
  },
  {
    "name": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "code": "KN"
  },
  {
    "name": "Saint Lucia",
    "dial_code": "+1758",
    "code": "LC"
  },
  {
    "name": "Saint Martin",
    "dial_code": "+590",
    "code": "MF"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "code": "PM"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "code": "VC"
  },
  {
    "name": "Samoa",
    "dial_code": "+685",
    "code": "WS"
  },
  {
    "name": "San Marino",
    "dial_code": "+378",
    "code": "SM"
  },
  {
    "name": "Sao Tome and Principe",
    "dial_code": "+239",
    "code": "ST"
  },
  {
    "name": "Saudi Arabia",
    "dial_code": "+966",
    "code": "SA"
  },
  {
    "name": "Senegal",
    "dial_code": "+221",
    "code": "SN"
  },
  {
    "name": "Serbia",
    "dial_code": "+381",
    "code": "RS"
  },
  {
    "name": "Seychelles",
    "dial_code": "+248",
    "code": "SC"
  },
  {
    "name": "Sierra Leone",
    "dial_code": "+232",
    "code": "SL"
  },
  {
    "name": "Singapore",
    "dial_code": "+65",
    "code": "SG"
  },
  {
    "name": "Slovakia",
    "dial_code": "+421",
    "code": "SK"
  },
  {
    "name": "Slovenia",
    "dial_code": "+386",
    "code": "SI"
  },
  {
    "name": "Solomon Islands",
    "dial_code": "+677",
    "code": "SB"
  },
  {
    "name": "Somalia",
    "dial_code": "+252",
    "code": "SO"
  },
  {
    "name": "South Africa",
    "dial_code": "+27",
    "code": "ZA"
  },
  {
    "name": "South Sudan",
    "dial_code": "+211",
    "code": "SS"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "code": "GS"
  },
  {
    "name": "Spain",
    "dial_code": "+34",
    "code": "ES"
  },
  {
    "name": "Sri Lanka",
    "dial_code": "+94",
    "code": "LK"
  },
  {
    "name": "Sudan",
    "dial_code": "+249",
    "code": "SD"
  },
  {
    "name": "Suriname",
    "dial_code": "+597",
    "code": "SR"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "code": "SJ"
  },
  {
    "name": "Swaziland",
    "dial_code": "+268",
    "code": "SZ"
  },
  {
    "name": "Sweden",
    "dial_code": "+46",
    "code": "SE"
  },
  {
    "name": "Switzerland",
    "dial_code": "+41",
    "code": "CH"
  },
  {
    "name": "Syrian Arab Republic",
    "dial_code": "+963",
    "code": "SY"
  },
  {
    "name": "Taiwan",
    "dial_code": "+886",
    "code": "TW"
  },
  {
    "name": "Tajikistan",
    "dial_code": "+992",
    "code": "TJ"
  },
  {
    "name": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "code": "TZ"
  },
  {
    "name": "Thailand",
    "dial_code": "+66",
    "code": "TH"
  },
  {
    "name": "Timor-Leste",
    "dial_code": "+670",
    "code": "TL"
  },
  {
    "name": "Togo",
    "dial_code": "+228",
    "code": "TG"
  },
  {
    "name": "Tokelau",
    "dial_code": "+690",
    "code": "TK"
  },
  {
    "name": "Tonga",
    "dial_code": "+676",
    "code": "TO"
  },
  {
    "name": "Trinidad and Tobago",
    "dial_code": "+1868",
    "code": "TT"
  },
  {
    "name": "Tunisia",
    "dial_code": "+216",
    "code": "TN"
  },
  {
    "name": "Turkey",
    "dial_code": "+90",
    "code": "TR"
  },
  {
    "name": "Turkmenistan",
    "dial_code": "+993",
    "code": "TM"
  },
  {
    "name": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "code": "TC"
  },
  {
    "name": "Tuvalu",
    "dial_code": "+688",
    "code": "TV"
  },
  {
    "name": "Uganda",
    "dial_code": "+256",
    "code": "UG"
  },
  {
    "name": "Ukraine",
    "dial_code": "+380",
    "code": "UA"
  },
  {
    "name": "United Arab Emirates",
    "dial_code": "+971",
    "code": "AE"
  },
  {
    "name": "United Kingdom",
    "dial_code": "+44",
    "code": "GB"
  },
  {
    "name": "United States / Canada",
    "dial_code": "+1",
    "code": "US"
  },
  {
    "name": "Uruguay",
    "dial_code": "+598",
    "code": "UY"
  },
  {
    "name": "Uzbekistan",
    "dial_code": "+998",
    "code": "UZ"
  },
  {
    "name": "Vanuatu",
    "dial_code": "+678",
    "code": "VU"
  },
  {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "code": "VE"
  },
  {
    "name": "Vietnam",
    "dial_code": "+84",
    "code": "VN"
  },
  {
    "name": "Virgin Islands, British",
    "dial_code": "+1284",
    "code": "VG"
  },
  {
    "name": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "code": "VI"
  },
  {
    "name": "Wallis and Futuna",
    "dial_code": "+681",
    "code": "WF"
  },
  {
    "name": "Yemen",
    "dial_code": "+967",
    "code": "YE"
  },
  {
    "name": "Zambia",
    "dial_code": "+260",
    "code": "ZM"
  },
  {
    "name": "Zimbabwe",
    "dial_code": "+263",
    "code": "ZW"
  }
];

export const STRS = {
  'READ_ONLY_LABEL': 'Read Only',
  'READ_ONLY_DESC': 'Read only access - Changes can not be saved.',
};

 
export const CSS_COLORS = {
  "aliceblue": "#f0f8ff",
  "antiquewhite": "#faebd7",
  "aqua": "#00ffff",
  "aquamarine": "#7fffd4",
  "azure": "#f0ffff",
  "beige": "#f5f5dc",
  "bisque": "#ffe4c4",
  "black": "#000000",
  "blanchedalmond": "#ffebcd",
  "blue": "#0000ff",
  "blueviolet": "#8a2be2",
  "brown": "#a52a2a",
  "burlywood": "#deb887",
  "cadetblue": "#5f9ea0",
  "chartreuse": "#7fff00",
  "chocolate": "#d2691e",
  "coral": "#ff7f50",
  "cornflowerblue": "#6495ed",
  "cornsilk": "#fff8dc",
  "crimson": "#dc143c",
  "cyan": "#00ffff",
  "darkblue": "#00008b",
  "darkcyan": "#008b8b",
  "darkgoldenrod": "#b8860b",
  "darkgray": "#a9a9a9",
  "darkgrey": "#a9a9a9",
  "darkgreen": "#006400",
  "darkkhaki": "#bdb76b",
  "darkmagenta": "#8b008b",
  "darkolivegreen": "#556b2f",
  "darkorange": "#ff8c00",
  "darkorchid": "#9932cc",
  "darkred": "#8b0000",
  "darksalmon": "#e9967a",
  "darkseagreen": "#8fbc8f",
  "darkslateblue": "#483d8b",
  "darkslategray": "#2f4f4f",
  "darkslategrey": "#2f4f4f",
  "darkturquoise": "#00ced1",
  "darkviolet": "#9400d3",
  "deeppink": "#ff1493",
  "deepskyblue": "#00bfff",
  "dimgray": "#696969",
  "dimgrey": "#696969",
  "dodgerblue": "#1e90ff",
  "firebrick": "#b22222",
  "floralwhite": "#fffaf0",
  "forestgreen": "#228b22",
  "fuchsia": "#ff00ff",
  "gainsboro": "#dcdcdc",
  "ghostwhite": "#f8f8ff",
  "gold": "#ffd700",
  "goldenrod": "#daa520",
  "gray": "#808080",
  "grey": "#808080",
  "green": "#008000",
  "greenyellow": "#adff2f",
  "honeydew": "#f0fff0",
  "hotpink": "#ff69b4",
  "indianred": "#cd5c5c",
  "indigo": "#4b0082",
  "ivory": "#fffff0",
  "khaki": "#f0e68c",
  "lavender": "#e6e6fa",
  "lavenderblush": "#fff0f5",
  "lawngreen": "#7cfc00",
  "lemonchiffon": "#fffacd",
  "lightblue": "#add8e6",
  "lightcoral": "#f08080",
  "lightcyan": "#e0ffff",
  "lightgoldenrodyellow": "#fafad2",
  "lightgray": "#d3d3d3",
  "lightgrey": "#d3d3d3",
  "lightgreen": "#90ee90",
  "lightpink": "#ffb6c1",
  "lightsalmon": "#ffa07a",
  "lightseagreen": "#20b2aa",
  "lightskyblue": "#87cefa",
  "lightslategray": "#778899",
  "lightslategrey": "#778899",
  "lightsteelblue": "#b0c4de",
  "lightyellow": "#ffffe0",
  "lime": "#00ff00",
  "limegreen": "#32cd32",
  "linen": "#faf0e6",
  "magenta": "#ff00ff",
  "maroon": "#800000",
  "mediumaquamarine": "#66cdaa",
  "mediumblue": "#0000cd",
  "mediumorchid": "#ba55d3",
  "mediumpurple": "#9370db",
  "mediumseagreen": "#3cb371",
  "mediumslateblue": "#7b68ee",
  "mediumspringgreen": "#00fa9a",
  "mediumturquoise": "#48d1cc",
  "mediumvioletred": "#c71585",
  "midnightblue": "#191970",
  "mintcream": "#f5fffa",
  "mistyrose": "#ffe4e1",
  "moccasin": "#ffe4b5",
  "navajowhite": "#ffdead",
  "navy": "#000080",
  "oldlace": "#fdf5e6",
  "olive": "#808000",
  "olivedrab": "#6b8e23",
  "orange": "#ffa500",
  "orangered": "#ff4500",
  "orchid": "#da70d6",
  "palegoldenrod": "#eee8aa",
  "palegreen": "#98fb98",
  "paleturquoise": "#afeeee",
  "palevioletred": "#db7093",
  "papayawhip": "#ffefd5",
  "peachpuff": "#ffdab9",
  "peru": "#cd853f",
  "pink": "#ffc0cb",
  "plum": "#dda0dd",
  "powderblue": "#b0e0e6",
  "purple": "#800080",
  "rebeccapurple": "#663399",
  "red": "#ff0000",
  "rosybrown": "#bc8f8f",
  "royalblue": "#4169e1",
  "saddlebrown": "#8b4513",
  "salmon": "#fa8072",
  "sandybrown": "#f4a460",
  "seagreen": "#2e8b57",
  "seashell": "#fff5ee",
  "sienna": "#a0522d",
  "silver": "#c0c0c0",
  "skyblue": "#87ceeb",
  "slateblue": "#6a5acd",
  "slategray": "#708090",
  "slategrey": "#708090",
  "snow": "#fffafa",
  "springgreen": "#00ff7f",
  "steelblue": "#4682b4",
  "tan": "#d2b48c",
  "teal": "#008080",
  "thistle": "#d8bfd8",
  "tomato": "#ff6347",
  "turquoise": "#40e0d0",
  "violet": "#ee82ee",
  "wheat": "#f5deb3",
  "white": "#ffffff",
  "whitesmoke": "#f5f5f5",
  "yellow": "#ffff00",
  "yellowgreen": "#9acd32"
};