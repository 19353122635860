import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Select from 'react-select';

import RouteLeavingGuard from '../../../Misc/RouteLeavingGuard';
import BusinessHours from '../../../Misc/BusinessHours';
import ToggleAndData from '../../../Misc/ToggleAndData';
import GenericSideModal from '../../../Misc/GenericSideModal';
import PreDefinedMessage from '../../../Engage/JourneyMessages/JMWhatsapp/PreDefinedMessage';

import { editDataAction, editDataClearAction } from '../../../../redux/actions/editDataActions';
import { validateEmail, ACCESS_LEVELS, getQuarters, getMonths, getWeeks, getDays } from '../../../../helpers';

import './style.css';

const alertMediumOptions = [];
alertMediumOptions.push({
  id: 'email',
  label: 'Email Alert',
});
// alertMediumOptions.push({
//   id: 'whatsapp',
//   label: 'Whatsapp Alert',
// });

const groupOverviewFrequencyOptions = [];
groupOverviewFrequencyOptions.push({
  value: 'weekly',
  label: 'Weekly',
});
groupOverviewFrequencyOptions.push({
  value: 'monthly',
  label: 'Monthly',
});
groupOverviewFrequencyOptions.push({
  value: 'quarterly',
  label: 'Quarterly',
});
const performanceFrequencyOptions = [];
performanceFrequencyOptions.push({
  value: 'daily',
  label: 'Daily',
});
performanceFrequencyOptions.push({
  value: 'weekly',
  label: 'Weekly',
});
performanceFrequencyOptions.push({
  value: 'monthly',
  label: 'Monthly',
});
performanceFrequencyOptions.push({
  value: 'quarterly',
  label: 'Quarterly',
});

const guestFeedbackFrequencyOptions = [];
guestFeedbackFrequencyOptions.push({
  value: 'daily',
  label: 'Daily',
});
guestFeedbackFrequencyOptions.push({
  value: 'weekly',
  label: 'Weekly',
});
guestFeedbackFrequencyOptions.push({
  value: 'monthly',
  label: 'Monthly',
});
guestFeedbackFrequencyOptions.push({
  value: 'quarterly',
  label: 'Quarterly',
});
const reviewSummaryFrequencyOptions = [];
reviewSummaryFrequencyOptions.push({
  value: 'daily',
  label: 'Daily',
});
reviewSummaryFrequencyOptions.push({
  value: 'weekly',
  label: 'Weekly',
});
reviewSummaryFrequencyOptions.push({
  value: 'monthly',
  label: 'Monthly',
});
reviewSummaryFrequencyOptions.push({
  value: 'quarterly',
  label: 'Quarterly',
});
const sendPeriodOptions = [];
sendPeriodOptions.push({
  value: 'daily',
  label: 'Daily',
});
sendPeriodOptions.push({
  value: 'weekly',
  label: 'Weekly',
});
sendPeriodOptions.push({
  value: 'monthly',
  label: 'Monthly',
});
sendPeriodOptions.push({
  value: 'quarterly',
  label: 'Quarterly',
});


const oopsFrequencyOptions = [];
oopsFrequencyOptions.push({
  id: 'daily',
  label: 'Daily',
});
oopsFrequencyOptions.push({
  id: 'weekly',
  label: 'Weekly',
});
oopsFrequencyOptions.push({
  id: 'monthly',
  label: 'Monthly',
});
oopsFrequencyOptions.push({
  id: 'quarterly',
  label: 'Quarterly',
});

const ratingOptions = [];
ratingOptions.push({
  id: 'all',
  label: 'All',
});
ratingOptions.push({
  id: '3-or-less',
  label: '3 Stars or less',
});
ratingOptions.push({
  id: '2-or-less',
  label: '2 Stars or less',
});
ratingOptions.push({
  id: '1-or-less',
  label: '1 Stars or less',
});

const reviewSummaryRtngOptions = [];
reviewSummaryRtngOptions.push({
  value: 'all',
  label: 'All',
});
reviewSummaryRtngOptions.push({
  value: '3-or-less',
  label: '3 Stars or less',
});
reviewSummaryRtngOptions.push({
  value: '2-or-less',
  label: '2 Stars or less',
});
reviewSummaryRtngOptions.push({
  value: '1-or-less',
  label: '1 Stars or less',
});


function CheckBoxRow(props) {
  const onCheckBoxChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('CheckBoxRow onCheckBoxChange props: ', props);
    }
    if (props.id === 'whatsapp' && !props.user.user_info.contact) return; // contact is missing
    props.onCheckBoxChange(props.id);
  };
  return (
    <div className={'entry ' + (props.value ? 'active' : '')}>
      <input type="checkbox" name={props.name} onChange={onCheckBoxChange}
        value={props.value ? 'on' : 'off'} checked={props.value}
        disabled={props.id === 'whatsapp' && !props.user.user_info.contact}
      />
      <div className="label">
        {
          props.id === 'email' &&
          <><span className="name" onClick={onCheckBoxChange}>{props.label}</span><span>( On {props.user.user_info.uid})</span></>
        }
        {
          props.id === 'whatsapp' &&
          <>
            <span className="name" onClick={onCheckBoxChange}>{props.label}</span>
            {
              props.user.user_info.contact
              ? <span>( On {props.user.user_info.contact})</span>
              : <span>( Cannot be enabled, updated your contact in your <Link to={'/profile/info'}>profile</Link>. )</span>
            }
          </>
        }
        {
          props.id !== 'email' && props.id !== 'whatsapp' &&
          <span className="name" onClick={onCheckBoxChange}>{props.label}</span>
        }
      </div>
    </div>
  );
}


function Reports(props) {
  const userState = useSelector((state) => state.userReducer);
  const locationState = useSelector((state) => state.locationReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let urlParams = useParams();
  let location = useLocation();

  const [groupOverviewReport, setGroupOverviewReport] = useState(false);
  const [groupOverviewReportFrequencyValues, setGroupOverviewReportFrequencyValues] = useState({});
  const [groupOverviewReportFrequencyValuesError, setGroupOverviewReportFrequencyValuesError] = useState('');
  const [groupOverviewReportValues, setGroupOverviewReportValues] = useState({'email': true});
  const [groupOverviewReportValuesError, setGroupOverviewReportValuesError] = useState('');
  const [groupOverviewPeriodOptions, setGroupOverviewPeriodOptions] = useState([]);

  const [monthlyPerformanceReport, setMonthlyPerformanceReport] = useState(false);
  const [performanceReportFrequencyValues, setPerformanceReportFrequencyValues] = useState({});
  const [performanceReportFrequencyValuesError, setPerformanceReportFrequencyValuesError] = useState('');
  const [monthlyPerformanceReportValues, setMonthlyPerformanceReportValues] = useState({'email': true});
  const [monthlyPerformanceReportValuesError, setMonthlyPerformanceReportValuesError] = useState('');
  const [performancePeriodOptions, setPerformancePeriodOptions] = useState([]);

  const [guestFeedbackSummaryReport, setGuestFeedbackSummaryReport] = useState(false);
  const [guestFeedbackSummaryReportFrequencyValues, setGuestFeedbackSummaryReportFrequencyValues] = useState({});
  const [guestFeedbackSummaryReportFrequencyValuesError, setGuestFeedbackSummaryReportFrequencyValuesError] = useState('');
  const [guestFeedbackSummaryReportMediumValues, setGuestFeedbackSummaryReportMediumValues] = useState({'email': true});
  const [guestFeedbackSummaryReportMediumValuesError, setGuestFeedbackSummaryReportMediumValuesError] = useState('');
  const [guestFeedbackPeriodOptions, setGuestFeedbackPeriodOptions] = useState([]);

  const [reviewSummaryReport, setReviewSummaryReport] = useState(false);
  const [reviewSummaryReportRatingValues, setReviewSummaryReportRatingValues] = useState({});
  const [reviewSummaryReportRatingValuesError, setReviewSummaryReportRatingValuesError] = useState('');
  const [reviewSummaryReportFrequencyValues, setReviewSummaryReportFrequencyValues] = useState({});
  const [reviewSummaryReportFrequencyValuesError, setReviewSummaryReportFrequencyValuesError] = useState('');
  const [reviewSummaryReportMediumValues, setReviewSummaryReportMediumValues] = useState({'email': true});
  const [reviewSummaryReportMediumValuesError, setReviewSummaryReportMediumValuesError] = useState('');
  const [reviewSummaryPeriodOptions, setReviewSummaryPeriodOptions] = useState([]);
  const [reviewSummaryRatingOptions, setReviewSummaryRatingOptions] = useState(reviewSummaryRtngOptions);

  const [oopsReport, setOopsReport] = useState(false);
  const [oopsReportFrequencyValues, setOopsReportFrequencyValues] = useState({});
  const [oopsReportFrequencyValuesError, setOopsReportFrequencyValuesError] = useState('');
  const [oopsReportValues, setOopsReportValues] = useState({'email': true});
  const [oopsReportValuesError, setOopsReportValuesError] = useState('');
  const [oopsPeriodOptions, setOopsPeriodOptions] = useState([]);

  const [formChanged, setFormChanged] = useState(false);

  const [sendForGroupOptions, setSendForGroupOptions] = useState([]);
  const [selectedGroupValue, setSelectedGroupValue] = useState({ label: 'Select Group', 'value': 'select-group' });
  const [selectedSendGroupPeriodValue, setSelectedSendGroupPeriodValue] = useState({ label: 'Select Period', 'value': 'select-period' });
  const [selectedGroupOverviewPeriodValue, setSelectedGroupOverviewPeriodValue] = useState({ label: 'Select Duration', 'value': 'select-duration' });

  const [selectedSendPerformancePeriodValue, setSelectedSendPerformancePeriodValue] = useState({ label: 'Select Period', 'value': 'select-period' });
  const [selectedPerformancePeriodValue, setSelectedPerformancePeriodValue] = useState({ label: 'Select Duration', 'value': 'select-duration' });

  const [selectedSendGuestFeedbackSummaryReportPeriodValue, setSelectedSendGuestFeedbackSummaryReportPeriodValue] = useState({ label: 'Select Period', 'value': 'select-period' });
  const [selectedGuestFeedbackPeriodValue, setSelectedGuestFeedbackPeriodValue] = useState({ label: 'Select Duration', 'value': 'select-duration' });

  const [selectedSendReviewSummaryReportPeriodValue, setSelectedSendReviewSummaryReportPeriodValue] = useState({ label: 'Select Period', 'value': 'select-period' });
  const [selectedReviewSummaryPeriodValue, setSelectedReviewSummaryPeriodValue] = useState({ label: 'Select Duration', 'value': 'select-duration' });
  const [selectedReviewSummaryRatingValue, setSelectedReviewSummaryRatingValue] = useState({ label: 'Select Rating', 'value': 'select-rating' });

  const [selectedSendOopsReportPeriodValue, setSelectedSendOopsReportPeriodValue] = useState({ label: 'Select Period', 'value': 'select-period' });
  const [selectedOopsReportPeriodValue, setSelectedOopsReportPeriodValue] = useState({ label: 'Select Duration', 'value': 'select-duration' });

  // analytics reporting
  useEffect(() => {
    try {
      const locationName = locationState.locations && locationState.locations[urlParams.locationidentifier]
        && locationState.locations[urlParams.locationidentifier].settings
        && locationState.locations[urlParams.locationidentifier].settings.info
        ? locationState.locations[urlParams.locationidentifier].settings.info.name || ''
        : '';
      document.title = `Settings - Reputation - Reports - ${locationName}`;
      window.gtag('event', 'page_view', {
        page_path: `${location.pathname}`,
        page_title: document.title,
        gt_user_id: userState.user.uid,
        gt_location_id: urlParams.locationidentifier,
        gt_location: locationName,
      });
    } catch (e) {}
  }, []);

  const setInitialValues = () => {
    const { locationProfile } = props;
    if (userState.user.user_info && userState.user.user_info.profile && userState.user.user_info.profile.reports) {
      const { group_overview } = userState.user.user_info.profile.reports;
      if (group_overview) {
        setGroupOverviewReport(group_overview.is_enabled || false);
        setGroupOverviewReportFrequencyValues(group_overview.frequency || {});
        setGroupOverviewReportValues(group_overview.medium || {});
      }
    }
    if (locationProfile.reputation) {
      const { reports } = locationProfile.reputation;
      if (reports) {
        const { monthly_performance, guest_feedback_summary, review_summary, oops_report } = reports;
        if (monthly_performance) {
          setMonthlyPerformanceReport(monthly_performance.is_enabled || false);
          setPerformanceReportFrequencyValues(monthly_performance.frequency || {});
          setMonthlyPerformanceReportValues(monthly_performance.medium || {});
        }
        if (guest_feedback_summary) {
          setGuestFeedbackSummaryReport(guest_feedback_summary.is_enabled || false);
          setGuestFeedbackSummaryReportFrequencyValues(guest_feedback_summary.frequency || {});
          setGuestFeedbackSummaryReportMediumValues(guest_feedback_summary.medium || {});
        }
        if (review_summary) {
          setReviewSummaryReport(review_summary.is_enabled || false);
          setReviewSummaryReportRatingValues(review_summary.rating || {});
          setReviewSummaryReportFrequencyValues(review_summary.frequency || {});
          setReviewSummaryReportMediumValues(review_summary.medium || {});
        }
        if (oops_report) {
          setOopsReport(oops_report.is_enabled || false);
          setOopsReportFrequencyValues(oops_report.frequency || {});
          setOopsReportValues(oops_report.medium || {});
        }
      }
    }
    // set group list
    let tempOptions = [];
    tempOptions.push({ label: 'Select Group', 'value': 'select-group' });
    if (
      props.settings.reputation && props.settings.reputation.reputation_groups &&
      props.settings.reputation.reputation_groups.list &&
      props.settings.reputation.reputation_groups.list.length &&
      props.settings.reputation.reputation_groups.keyValue
    ) {
      props.settings.reputation.reputation_groups.list.forEach((item) => {
        if (props.settings.reputation.reputation_groups.keyValue[item]) {
          tempOptions.push({
            label: props.settings.reputation.reputation_groups.keyValue[item].name,
            value: item,
          });
        }
      });
      tempOptions.push({
        label: 'All Locations',
        value: 'default',
      });
    }
    setSendForGroupOptions(tempOptions);

    setFormChanged(false);
  };


  useEffect(() => {
    setInitialValues();
  }, []);

  // useEffect(() => {
  //   if (props.settings.reputation &&
  //     !props.settings.reputation.editInProgress &&
  //     props.settings.reputation.editStatus &&
  //     props.settings.reputation.editStatus.success
  //   ) {
  //     setFormChanged(false);
  //   }
  // }, [props.settings.reputation]);


  /* ************ Group Overview Report *********** */

  const onGroupOverviewReportChange = (e) => {
    const { name, checked } = e.target;
    setGroupOverviewReport(checked);
    setFormChanged(true);
  };

  const onGroupOverviewAlertMediumCheckBoxChange = (id) => {
    let cValue = groupOverviewReportValues[id] || false;
    if (cValue) {
      if (Object.keys(groupOverviewReportValues).filter(el => groupOverviewReportValues[el]).length === 1) {
        return; // one is required
      }
    }
    setGroupOverviewReportValues({
      ...groupOverviewReportValues,
      [id]: !cValue,
    });
    setGroupOverviewReportValuesError('');
    setFormChanged(true);
  };

  const onGroupOverviewReportFrequencyCheckBoxChange = (id) => {
    let cValue = groupOverviewReportFrequencyValues[id] || false;
    if (cValue) {
      if (Object.keys(groupOverviewReportFrequencyValues).filter(el => groupOverviewReportFrequencyValues[el]).length === 1) {
        return; // one is required
      }
    }
    setGroupOverviewReportFrequencyValues({
      ...groupOverviewReportFrequencyValues,
      [id]: !cValue,
    });
    setGroupOverviewReportFrequencyValuesError('');
    setFormChanged(true);
  };

  const onSendGroupPeriodChange = (value) => {
    setSelectedSendGroupPeriodValue(value);
    if (value.value) {
      setSelectedGroupOverviewPeriodValue({ label: 'Select Duration', 'value': 'select-duration' });
      if (value.value === 'quarterly') {
        setGroupOverviewPeriodOptions(getQuarters(12));
      }
      if (value.value === 'monthly') {
        setGroupOverviewPeriodOptions(getMonths(24));
      }
      if (value.value === 'weekly') {
        setGroupOverviewPeriodOptions(getWeeks(26));
      }
    }
  };
  const onGroupOverviewPeriodChange = (value) => {
    setSelectedGroupOverviewPeriodValue(value);
  };
  const onGroupChange = (value) => {
    setSelectedGroupValue(value);
  };

  const onGroupOverviewSendNowClick = (e) => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile['send_group_overview_InProgress']
    ) {
      return;
    }
    if (!selectedGroupValue.value || selectedGroupValue.value === 'select-group') return;
    if (!selectedSendGroupPeriodValue.value || selectedSendGroupPeriodValue.value === 'select-period') return;
    if (!selectedGroupOverviewPeriodValue.value || selectedGroupOverviewPeriodValue.value === 'select-duration') return;

    const params = {
      // location__id: urlParams.locationidentifier,
      group_id: selectedGroupValue.value, // '626587dc5f49bc000952a988',
      type: 'group_overview',
      medium: 'email',
    };
    params['period'] = selectedSendGroupPeriodValue.value;
    params['duration'] = selectedGroupOverviewPeriodValue.value;

    dispatch(editDataAction({
      op: 'send_reputation_report',
      show_alert: true,
      alert_msg: 'Sent successfully',
      show_alert_timeout: 2000,
      params,
    }));
  };


  /* ************ Monthly Performance Report *********** */

  const onMonthlyPerformanceReportChange = (e) => {
    const { name, checked } = e.target;
    setMonthlyPerformanceReport(checked);
    setFormChanged(true);
  };

  const onMonthlyPerformanceAlertMediumCheckBoxChange = (id) => {
    let cValue = monthlyPerformanceReportValues[id] || false;
    if (cValue) {
      if (Object.keys(monthlyPerformanceReportValues).filter(el => monthlyPerformanceReportValues[el]).length === 1) {
        return; // one is required
      }
    }
    setMonthlyPerformanceReportValues({
      ...monthlyPerformanceReportValues,
      [id]: !cValue,
    });
    setMonthlyPerformanceReportValuesError('');
    setFormChanged(true);
  };

  const onPerformanceReportFrequencyCheckBoxChange = (id) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onPerformanceReportFrequencyCheckBoxChange id: ', id);
    }
    let cValue = performanceReportFrequencyValues[id] || false;
    if (cValue) {
      if (Object.keys(performanceReportFrequencyValues).filter(el => performanceReportFrequencyValues[el]).length === 1) {
        return; // one is required
      }
    }
    setPerformanceReportFrequencyValues({
      ...performanceReportFrequencyValues,
      [id]: !cValue,
    });
    setPerformanceReportFrequencyValuesError('');
    setFormChanged(true);
  };

  const onSendPerformancePeriodChange = (value) => {
    setSelectedSendPerformancePeriodValue(value);
    if (value.value) {
      setSelectedPerformancePeriodValue({ label: 'Select Duration', 'value': 'select-duration' });
      if (value.value === 'quarterly') {
        setPerformancePeriodOptions(getQuarters(12));
      }
      if (value.value === 'monthly') {
        setPerformancePeriodOptions(getMonths(24));
      }
      if (value.value === 'weekly') {
        setPerformancePeriodOptions(getWeeks(26));
      }
      if (value.value === 'daily') {
        setPerformancePeriodOptions(getDays(7));
      }
    }
  };
  const onPerformancePeriodChange = (value) => {
    setSelectedPerformancePeriodValue(value);
  };

  const onPerformanceReportSendNowClick = (e) => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile['send_performance_report_InProgress']
    ) {
      return;
    }
    if (!selectedSendPerformancePeriodValue.value || selectedSendPerformancePeriodValue.value === 'select-period') return;
    const params = {
      location__id: urlParams.locationidentifier,
      type: 'performance_report',
      medium: 'email',
    };
    params['period'] = selectedSendPerformancePeriodValue.value;
    if (!selectedPerformancePeriodValue.value || selectedPerformancePeriodValue.value === 'select-duration') return;

    params['duration'] = selectedPerformancePeriodValue.value;

    dispatch(editDataAction({
      op: 'send_reputation_report',
      show_alert: true,
      alert_msg: 'Sent successfully',
      params,
    }));
  };


  /* ************ Guest Feedback Summary Report *********** */

  const onGuestFeedbackSummaryReportChange = (e) => {
    const { name, checked } = e.target;
    setGuestFeedbackSummaryReport(checked);
    setFormChanged(true);
  };

  const onGuestFeedbackSummaryAlertMediumCheckBoxChange = (id) => {
    let cValue = guestFeedbackSummaryReportMediumValues[id] || false;
    if (cValue) {
      if (Object.keys(guestFeedbackSummaryReportMediumValues).filter(el => guestFeedbackSummaryReportMediumValues[el]).length === 1) {
        return; // one is required
      }
    }
    setGuestFeedbackSummaryReportMediumValues({
      ...guestFeedbackSummaryReportMediumValues,
      [id]: !cValue,
    });
    setGuestFeedbackSummaryReportMediumValuesError('');
    setFormChanged(true);
  };

  const onGuestFeedbackSummaryFrequencyCheckBoxChange = (id) => {
    let cValue = guestFeedbackSummaryReportFrequencyValues[id] || false;
    if (cValue) {
      if (Object.keys(guestFeedbackSummaryReportFrequencyValues).filter(el => guestFeedbackSummaryReportFrequencyValues[el]).length === 1) {
        return; // one is required
      }
    }
    setGuestFeedbackSummaryReportFrequencyValues({
      ...guestFeedbackSummaryReportFrequencyValues,
      [id]: !cValue,
    });
    setGuestFeedbackSummaryReportFrequencyValuesError('');
    setFormChanged(true);
  };

  const onSendGuestFeedbackSummaryReportPeriodChange = (value) => {
    setSelectedSendGuestFeedbackSummaryReportPeriodValue(value);
    if (value.value) {
      setSelectedGuestFeedbackPeriodValue({ label: 'Select Duration', 'value': 'select-duration' });
      if (value.value === 'quarterly') {
        setGuestFeedbackPeriodOptions(getQuarters(12));
      }
      if (value.value === 'monthly') {
        setGuestFeedbackPeriodOptions(getMonths(24));
      }
      if (value.value === 'weekly') {
        setGuestFeedbackPeriodOptions(getWeeks(26));
      }
      if (value.value === 'daily') {
        setGuestFeedbackPeriodOptions(getDays(10));
      }
    }
  };
  const onGuestFeedbackPeriodChange = (value) => {
    setSelectedGuestFeedbackPeriodValue(value);
  };

  const onGuestFeedbackSummaryReportSendNowClick = (e) => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile['send_guest_feedback_summary_report_InProgress']
    ) {
      return;
    }
    if (!selectedSendGuestFeedbackSummaryReportPeriodValue.value || selectedSendGuestFeedbackSummaryReportPeriodValue.value === 'select-period') return;
    const params = {
      location__id: urlParams.locationidentifier,
      type: 'guest_feedback_summary_report',
      medium: 'email',
    };
    params['period'] = selectedSendGuestFeedbackSummaryReportPeriodValue.value;
    if (!selectedGuestFeedbackPeriodValue.value || selectedGuestFeedbackPeriodValue.value === 'select-duration') return;

    params['duration'] = selectedGuestFeedbackPeriodValue.value;

    dispatch(editDataAction({
      op: 'send_reputation_report',
      show_alert: true,
      alert_msg: 'Sent successfully',
      params,
    }));
  };


  /* ************ Review Summary Report *********** */

  const onReviewSummaryReportChange = (e) => {
    const { name, checked } = e.target;
    setReviewSummaryReport(checked);
    setFormChanged(true);
  };

  const onReviewSummaryRatingCheckBoxChange = (id) => {
    let cValue = reviewSummaryReportRatingValues[id] || false;
    if (cValue) {
      if (Object.keys(reviewSummaryReportRatingValues).filter(el => reviewSummaryReportRatingValues[el]).length === 1) {
        return; // one is required
      }
    }
    setReviewSummaryReportRatingValues({
      // ...reviewSummaryReportRatingValues,
      [id]: !cValue,
    });
    setReviewSummaryReportRatingValuesError('');
    setFormChanged(true);
  };

  const onReviewSummaryAlertMediumCheckBoxChange = (id) => {
    let cValue = reviewSummaryReportMediumValues[id] || false;
    if (cValue) {
      if (Object.keys(reviewSummaryReportMediumValues).filter(el => reviewSummaryReportMediumValues[el]).length === 1) {
        return; // one is required
      }
    }
    setReviewSummaryReportMediumValues({
      ...reviewSummaryReportMediumValues,
      [id]: !cValue,
    });
    setReviewSummaryReportMediumValuesError('');
    setFormChanged(true);
  };

  const onReviewSummaryFrequencyCheckBoxChange = (id) => {
    let cValue = reviewSummaryReportFrequencyValues[id] || false;
    if (cValue) {
      if (Object.keys(reviewSummaryReportFrequencyValues).filter(el => reviewSummaryReportFrequencyValues[el]).length === 1) {
        return; // one is required
      }
    }
    setReviewSummaryReportFrequencyValues({
      ...reviewSummaryReportFrequencyValues,
      [id]: !cValue,
    });
    setReviewSummaryReportFrequencyValuesError('');
    setFormChanged(true);
  };

  const onSendReviewSummaryReportPeriodChange = (value) => {
    setSelectedSendReviewSummaryReportPeriodValue(value);
    if (value.value) {
      setSelectedReviewSummaryPeriodValue({ label: 'Select Duration', 'value': 'select-duration' });
      if (value.value === 'quarterly') {
        setReviewSummaryPeriodOptions(getQuarters(12));
      }
      if (value.value === 'monthly') {
        setReviewSummaryPeriodOptions(getMonths(24));
      }
      if (value.value === 'weekly') {
        setReviewSummaryPeriodOptions(getWeeks(26));
      }
      if (value.value === 'daily') {
        setReviewSummaryPeriodOptions(getDays(10));
      }
    }
  };
  const onReviewSummaryPeriodChange = (value) => {
    setSelectedReviewSummaryPeriodValue(value);
  };
  const onReviewSummaryRatingChange = (value) => {
    setSelectedReviewSummaryRatingValue(value);
  };

  const onReviewSummaryReportSendNowClick = (e) => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile['send_review_summary_report_InProgress']
    ) {
      return;
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onReviewSummaryReportSendNowClick selectedSendReviewSummaryReportPeriodValue: ', selectedSendReviewSummaryReportPeriodValue);
      console.log('onReviewSummaryReportSendNowClick selectedReviewSummaryPeriodValue: ', selectedReviewSummaryPeriodValue);
      console.log('onReviewSummaryReportSendNowClick selectedReviewSummaryRatingValue: ', selectedReviewSummaryRatingValue);
    }

    if (!selectedSendReviewSummaryReportPeriodValue.value || selectedSendReviewSummaryReportPeriodValue.value === 'select-period') return;
    if (!selectedReviewSummaryPeriodValue.value || selectedReviewSummaryPeriodValue.value === 'select-duration') return;
    if (!selectedReviewSummaryRatingValue.value || selectedReviewSummaryRatingValue.value === 'select-rating') return;

    const params = {
      location__id: urlParams.locationidentifier,
      type: 'review_summary_report',
      medium: 'email',
    };
    params['period'] = selectedSendReviewSummaryReportPeriodValue.value;
    params['duration'] = selectedReviewSummaryPeriodValue.value;
    params['rating'] = selectedReviewSummaryRatingValue.value;

    dispatch(editDataAction({
      op: 'send_reputation_report',
      show_alert: true,
      alert_msg: 'Sent successfully',
      params,
    }));
  };

  /* ************ Oops Report *********** */

  const onOopsReportChange = (e) => {
    const { name, checked } = e.target;
    setOopsReport(checked);
    setFormChanged(true);
    if (checked) {
      setOopsReportValues({
        ...oopsReportValues,
        ['email']: true,
        ['whatsapp']: false,
      });
    }
  };

  const onOopsMediumCheckBoxChange = (id) => {
    let cValue = oopsReportValues[id] || false;
    if (cValue) {
      if (Object.keys(oopsReportValues).filter(el => oopsReportValues[el]).length === 1) {
        return; // one is required
      }
    }
    setOopsReportValues({
      ...oopsReportValues,
      [id]: !cValue,
    });
    setOopsReportValuesError('');
    setFormChanged(true);
  };

  const onOopsReportFrequencyCheckBoxChange = (id) => {
    let cValue = oopsReportFrequencyValues[id] || false;
    if (cValue) {
      if (Object.keys(oopsReportFrequencyValues).filter(el => oopsReportFrequencyValues[el]).length === 1) {
        return; // one is required
      }
    }
    setOopsReportFrequencyValues({
      ...oopsReportFrequencyValues,
      [id]: !cValue,
    });
    setOopsReportFrequencyValuesError('');
    setFormChanged(true);
    setOopsReportValues({
      ...oopsReportValues,
      ['email']: true,
      ['whatsapp']: false,
    });
  };

  const onSendOopsReportPeriodChange = (value) => {
    setSelectedSendOopsReportPeriodValue(value);
    if (value.value) {
      setSelectedOopsReportPeriodValue({ label: 'Select Duration', 'value': 'select-duration' });
      if (value.value === 'quarterly') {
        setOopsPeriodOptions(getQuarters(12));
      }
      if (value.value === 'monthly') {
        setOopsPeriodOptions(getMonths(24));
      }
      if (value.value === 'weekly') {
        setOopsPeriodOptions(getWeeks(26));
      }
      if (value.value === 'daily') {
        setOopsPeriodOptions(getDays(10));
      }
    }
  };
  const onOopsReportPeriodChange = (value) => {
    setSelectedOopsReportPeriodValue(value);
  };

  const onOopsReportSendNowClick = (e) => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      userState.user.user_info.profile['send_oops_report_InProgress']
    ) {
      return;
    }
    if (!selectedSendOopsReportPeriodValue.value || selectedSendOopsReportPeriodValue.value === 'select-period') return;
    const params = {
      location__id: urlParams.locationidentifier,
      type: 'oops_report',
      medium: 'email',
    };
    params['period'] = selectedSendOopsReportPeriodValue.value;
    if (!selectedOopsReportPeriodValue.value || selectedOopsReportPeriodValue.value === 'select-duration') return;

    dispatch(editDataAction({
      op: 'send_reputation_report',
      show_alert: true,
      alert_msg: 'Sent successfully',
      params,
    }));
  };


  /* Submit */




  const onSubmit = (e) => {
    e.preventDefault();
    if (formChanged) performSave();
  };

  const performSave = () => {
    let hasError = false;

    const { locationProfile } = props;
    if (locationProfile && locationProfile.reputation && locationProfile.reputation.reports &&
      locationProfile.reputation.reports.editInProgress
    ) {
      return;
    }

    if (groupOverviewReport) {
      if (!groupOverviewReportFrequencyValues['weekly'] &&
        !groupOverviewReportFrequencyValues['monthly'] &&
        !groupOverviewReportFrequencyValues['quarterly']
      ) {
        setGroupOverviewReportFrequencyValuesError('error');
        hasError = true;
      } else {
        setGroupOverviewReportFrequencyValuesError('');
      }
      if (!groupOverviewReportValues['email'] && !groupOverviewReportValues['whatsapp']) {
        setGroupOverviewReportValuesError('error');
        hasError = true;
      } else {
        setGroupOverviewReportValuesError('');
      }
    }
    if (monthlyPerformanceReport) {
      if (!performanceReportFrequencyValues['daily'] &&
        !performanceReportFrequencyValues['weekly'] &&
        !performanceReportFrequencyValues['monthly'] &&
        !performanceReportFrequencyValues['quarterly']
      ) {
        setPerformanceReportFrequencyValuesError('error');
        hasError = true;
      } else {
        setPerformanceReportFrequencyValuesError('');
      }
      if (!monthlyPerformanceReportValues['email'] && !monthlyPerformanceReportValues['whatsapp']) {
        setMonthlyPerformanceReportValuesError('error');
        hasError = true;
      } else {
        setMonthlyPerformanceReportValuesError('');
      }
    }
    if (guestFeedbackSummaryReport) {
      if (!guestFeedbackSummaryReportFrequencyValues['daily'] &&
        !guestFeedbackSummaryReportFrequencyValues['weekly'] &&
        !guestFeedbackSummaryReportFrequencyValues['monthly']
      ) {
        setGuestFeedbackSummaryReportFrequencyValuesError('error');
        hasError = true;
      } else {
        setGuestFeedbackSummaryReportFrequencyValuesError('');
      }
      if (!guestFeedbackSummaryReportMediumValues['email'] && !guestFeedbackSummaryReportMediumValues['whatsapp']) {
        setGuestFeedbackSummaryReportMediumValuesError('error');
        hasError = true;
      } else {
        setGuestFeedbackSummaryReportMediumValuesError('');
      }
    }
    if (reviewSummaryReport) {
      if (!reviewSummaryReportRatingValues['all'] &&
        !reviewSummaryReportRatingValues['3-or-less'] &&
        !reviewSummaryReportRatingValues['2-or-less'] &&
        !reviewSummaryReportRatingValues['1-or-less']
      ) {
        setReviewSummaryReportRatingValuesError('error');
        hasError = true;
      } else {
        setReviewSummaryReportRatingValuesError('');
      }
      if (!reviewSummaryReportFrequencyValues['daily'] &&
        !reviewSummaryReportFrequencyValues['weekly'] &&
        !reviewSummaryReportFrequencyValues['monthly']
      ) {
        setReviewSummaryReportFrequencyValuesError('error');
        hasError = true;
      } else {
        setReviewSummaryReportFrequencyValuesError('');
      }
      if (!reviewSummaryReportMediumValues['email'] && !reviewSummaryReportMediumValues['whatsapp']) {
        setReviewSummaryReportMediumValuesError('error');
        hasError = true;
      } else {
        setReviewSummaryReportMediumValuesError('');
      }
    }
    if (oopsReport) {
      if (!oopsReportValues['email'] && !oopsReportValues['whatsapp']) {
        setOopsReportValuesError('error');
        hasError = true;
      } else {
        setOopsReportValuesError('');
      }
      if (!oopsReportFrequencyValues['daily'] &&
        !oopsReportFrequencyValues['weekly'] &&
        !oopsReportFrequencyValues['monthly']
      ) {
        setOopsReportFrequencyValuesError('error');
        hasError = true;
      } else {
        setOopsReportFrequencyValuesError('');
      }
    }

    if (hasError) return;


    const params = {
      location__id: urlParams.locationidentifier,
    };

    let reports = {};

    if (userState.user.user_info && userState.user.user_info.profile &&
      (
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_MANAGER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.OWNER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT // ||
        // (userState.user.user_info.profile.locations &&
        //   userState.user.user_info.profile.locations[urlParams.locationidentifier] &&
        //   userState.user.user_info.profile.locations[urlParams.locationidentifier].access_level === ACCESS_LEVELS.LOCATION_ADMIN
        // )
      )
    ) {
      reports['group_overview'] = {};
      reports['group_overview']['is_enabled'] = groupOverviewReport;
      reports['group_overview']['frequency'] = {};
      if (groupOverviewReportFrequencyValues['weekly']) reports['group_overview']['frequency']['weekly'] = true;
      if (groupOverviewReportFrequencyValues['monthly']) reports['group_overview']['frequency']['monthly'] = true;
      if (groupOverviewReportFrequencyValues['quarterly']) reports['group_overview']['frequency']['quarterly'] = true;
      reports['group_overview']['medium'] = {};
      if (groupOverviewReportValues['email']) reports['group_overview']['medium']['email'] = true;
      if (groupOverviewReportValues['whatsapp']) reports['group_overview']['medium']['whatsapp'] = true;
    }

    reports['monthly_performance'] = {};
    reports['monthly_performance']['is_enabled'] = monthlyPerformanceReport;
    reports['monthly_performance']['frequency'] = {};
    if (performanceReportFrequencyValues['daily']) reports['monthly_performance']['frequency']['daily'] = true;
    if (performanceReportFrequencyValues['weekly']) reports['monthly_performance']['frequency']['weekly'] = true;
    if (performanceReportFrequencyValues['monthly']) reports['monthly_performance']['frequency']['monthly'] = true;
    if (performanceReportFrequencyValues['quarterly']) reports['monthly_performance']['frequency']['quarterly'] = true;
    reports['monthly_performance']['medium'] = {};
    if (monthlyPerformanceReportValues['email']) reports['monthly_performance']['medium']['email'] = true;
    if (monthlyPerformanceReportValues['whatsapp']) reports['monthly_performance']['medium']['whatsapp'] = true;
    // reports['monthly_performance']['frequency'] = {};
    // reports['monthly_performance']['frequency']['monthly'] = true;

    reports['guest_feedback_summary'] = {};
    reports['guest_feedback_summary']['is_enabled'] = guestFeedbackSummaryReport;
    reports['guest_feedback_summary']['frequency'] = {};
    if (guestFeedbackSummaryReportFrequencyValues['daily']) reports['guest_feedback_summary']['frequency']['daily'] = true;
    if (guestFeedbackSummaryReportFrequencyValues['weekly']) reports['guest_feedback_summary']['frequency']['weekly'] = true;
    if (guestFeedbackSummaryReportFrequencyValues['monthly']) reports['guest_feedback_summary']['frequency']['monthly'] = true;
    reports['guest_feedback_summary']['medium'] = {};
    if (guestFeedbackSummaryReportMediumValues['email']) reports['guest_feedback_summary']['medium']['email'] = true;
    if (guestFeedbackSummaryReportMediumValues['whatsapp']) reports['guest_feedback_summary']['medium']['whatsapp'] = true;

    reports['review_summary'] = {};
    reports['review_summary']['is_enabled'] = reviewSummaryReport;
    reports['review_summary']['rating'] = {};
    if (reviewSummaryReportRatingValues['all']) reports['review_summary']['rating']['all'] = true;
    if (reviewSummaryReportRatingValues['3-or-less']) reports['review_summary']['rating']['3-or-less'] = true;
    if (reviewSummaryReportRatingValues['2-or-less']) reports['review_summary']['rating']['2-or-less'] = true;
    if (reviewSummaryReportRatingValues['1-or-less']) reports['review_summary']['rating']['1-or-less'] = true;
    reports['review_summary']['frequency'] = {};
    if (reviewSummaryReportFrequencyValues['daily']) reports['review_summary']['frequency']['daily'] = true;
    if (reviewSummaryReportFrequencyValues['weekly']) reports['review_summary']['frequency']['weekly'] = true;
    if (reviewSummaryReportFrequencyValues['monthly']) reports['review_summary']['frequency']['monthly'] = true;
    reports['review_summary']['medium'] = {};
    if (reviewSummaryReportMediumValues['email']) reports['review_summary']['medium']['email'] = true;
    if (reviewSummaryReportMediumValues['whatsapp']) reports['review_summary']['medium']['whatsapp'] = true;

    reports['oops_report'] = {};
    reports['oops_report']['is_enabled'] = oopsReport;
    reports['oops_report']['frequency'] = {};
    if (oopsReportFrequencyValues['daily']) reports['oops_report']['frequency']['daily'] = true;
    if (oopsReportFrequencyValues['weekly']) reports['oops_report']['frequency']['weekly'] = true;
    if (oopsReportFrequencyValues['monthly']) reports['oops_report']['frequency']['monthly'] = true;
    reports['oops_report']['medium'] = {};
    if (oopsReportValues['email']) reports['oops_report']['medium']['email'] = true;
    if (oopsReportValues['whatsapp']) reports['oops_report']['medium']['whatsapp'] = true;

    params['reports'] = reports;

    if (process.env.NODE_ENV !== 'production') {
      console.log('performSave params: ', params);
    }
    params.source = 'engage';
    dispatch(editDataAction({
      op: 'edit_reputation_reports',
      show_alert: true,
      params,
    }));
    setFormChanged(false);
  };


  const renderGroupOverviewReportBox = () => {
    if (userState.user.user_info && userState.user.user_info.profile &&
      (
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_MANAGER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.OWNER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT
      ) &&
      Object.keys(userState.user.user_info.profile.locations).length > 1
    ) {
      return (
        <>
          <div className="gBCtRw hasTgl">
            <div className="lt">
              <p className="title">Group Overview Report: {groupOverviewReport ? 'ON' : 'OFF'}</p>
              <p className="desc">Overview report for all groups will be sent to you.</p>
            </div>
            <div className="rt">
              <div className="gToggleWrap">
                <div className="gToggle">
                  <input type="checkbox" id="groupOverviewReport" checked={groupOverviewReport} onChange={onGroupOverviewReportChange}/><label htmlFor="groupOverviewReport">Toggle</label>
                </div>
              </div>
            </div>
          </div>
          <div className={'gBCtRw alrtOpts frequency' + (!groupOverviewReport ? ' inactive' : '')}>
            <div className="lt">
              <p className={'title ' + (groupOverviewReportFrequencyValuesError ? 'error' : '')}>
                How often would you like to receive this report?
                {
                  groupOverviewReport ? ' (Atleast one is required.)' : ''
                }
              </p>
              <div className="alertOptions">
                {
                  groupOverviewFrequencyOptions.map((item) => {
                    return <CheckBoxRow
                      id={item.value}
                      name={item.value}
                      label={item.label}
                      value={groupOverviewReportFrequencyValues[item.value]}
                      onCheckBoxChange={onGroupOverviewReportFrequencyCheckBoxChange}
                      user={userState.user}
                    />
                  })
                }
              </div>
              {
                groupOverviewReportFrequencyValuesError &&
                <div className="gErr">
                  Required
                </div>
              }
            </div>
          </div>
          <div className={'gBCtRw alrtOpts medium ' + (!groupOverviewReport ? ' inactive' : '')}>
            <div className="lt">
              <p className={'title ' + (groupOverviewReportValuesError ? 'error' : '')}>
                How would you like to receive reports ?
                {/*
                  groupOverviewReport ? ' (Atleast one is required.)' : ''
                */}
              </p>
              <div className="alertOptions">
                {
                  alertMediumOptions.map((item) => {
                    return <CheckBoxRow
                      id={item.id}
                      name={item.id}
                      label={item.label}
                      value={groupOverviewReportValues[item.id]}
                      onCheckBoxChange={onGroupOverviewAlertMediumCheckBoxChange}
                      user={userState.user}
                    />
                  })
                }
              </div>
            </div>
          </div>
          <div className="gBCtRw sndNw">
            <div className="lt">
              <p className="title">Do you want to send a report now ?</p>
            </div>
            <div className="rt">
              <div className="selGrp">
                <Select
                  options={groupOverviewFrequencyOptions}
                  onChange={onSendGroupPeriodChange}
                  value={selectedSendGroupPeriodValue}
                  inputMode={"none"}
                />
              </div>
              <div className={'selGrp ' + (selectedSendGroupPeriodValue.value ? selectedSendGroupPeriodValue.value : '')}>
                <Select
                  options={groupOverviewPeriodOptions}
                  onChange={onGroupOverviewPeriodChange}
                  value={selectedGroupOverviewPeriodValue}
                  inputMode={"none"}
                />
              </div>
            </div>
            <div className="rt">
              <div className="selGrp">
                <Select
                  options={sendForGroupOptions}
                  onChange={onGroupChange}
                  value={selectedGroupValue}
                  inputMode={"text"}
                />
              </div>
              <button className="gBtn" onClick={onGroupOverviewSendNowClick}>
                Send Now
                {
                  userState.user.user_info && userState.user.user_info.profile &&
                  userState.user.user_info.profile['send_group_overview_InProgress'] &&
                  <span><i className="fa fa-spinner fa-spin" /></span>
                }
              </button>
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const renderMonthlyPerformanceReportBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Performance Report: {monthlyPerformanceReport ? 'ON' : 'OFF'}</p>
            <p className="desc">High level summary of your performance.</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="monthlyPerformanceReport" checked={monthlyPerformanceReport} onChange={onMonthlyPerformanceReportChange}/><label htmlFor="monthlyPerformanceReport">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts frequency' + (!monthlyPerformanceReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (performanceReportFrequencyValuesError ? 'error' : '')}>
              How often would you like to receive this report?
              {
                monthlyPerformanceReport ? ' (Atleast one is required.)' : ''
              }
            </p>
            <div className="alertOptions">
              {
                performanceFrequencyOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.value}
                    name={item.value}
                    label={item.label}
                    value={performanceReportFrequencyValues[item.value]}
                    onCheckBoxChange={onPerformanceReportFrequencyCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
            {
              performanceReportFrequencyValuesError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
        </div>
        <div className={'gBCtRw alrtOpts medium' + (!monthlyPerformanceReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (monthlyPerformanceReportValuesError ? 'error' : '')}>
              How would you like to receive reports ?
              {/*
                monthlyPerformanceReport ? ' (Atleast one is required.)' : ''
              */}
            </p>
            <div className="alertOptions">
              {
                alertMediumOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={monthlyPerformanceReportValues[item.id]}
                    onCheckBoxChange={onMonthlyPerformanceAlertMediumCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="gBCtRw sndNw">
          <div className="lt">
            <p className="title">Do you want to send a report now ?</p>
          </div>
          <div className="rt">
            <div className="selGrp">
              <Select
                options={performanceFrequencyOptions}
                onChange={onSendPerformancePeriodChange}
                value={selectedSendPerformancePeriodValue}
                inputMode={"none"}
              />
            </div>
            <div className={'selGrp ' + (selectedSendPerformancePeriodValue.value ? selectedSendPerformancePeriodValue.value : '')}>
              <Select
                options={performancePeriodOptions}
                onChange={onPerformancePeriodChange}
                value={selectedPerformancePeriodValue}
                inputMode={"none"}
              />
            </div>
            <button className="gBtn" onClick={onPerformanceReportSendNowClick}>
              Send Now
              {
                userState.user.user_info && userState.user.user_info.profile &&
                userState.user.user_info.profile['send_performance_report_InProgress'] &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderGuestFeedbackSummaryReportBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Direct Guest Feedback Summary Report: {guestFeedbackSummaryReport ? 'ON' : 'OFF'}</p>
            <p className="desc">Guest feedback summary.</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="guestFeedbackSummaryReport" checked={guestFeedbackSummaryReport} onChange={onGuestFeedbackSummaryReportChange}/><label htmlFor="guestFeedbackSummaryReport">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts frequency' + (!guestFeedbackSummaryReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (guestFeedbackSummaryReportFrequencyValuesError ? 'error' : '')}>
              How often would you like to receive this report?
              {
                monthlyPerformanceReport ? ' (Atleast one is required.)' : ''
              }
            </p>
            <div className="alertOptions">
              {
                guestFeedbackFrequencyOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.value}
                    name={item.value}
                    label={item.label}
                    value={guestFeedbackSummaryReportFrequencyValues[item.value]}
                    onCheckBoxChange={onGuestFeedbackSummaryFrequencyCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
            {
              guestFeedbackSummaryReportFrequencyValuesError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
        </div>
        <div className={'gBCtRw alrtOpts medium' + (!guestFeedbackSummaryReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (guestFeedbackSummaryReportFrequencyValuesError ? 'error' : '')}>
              How would you like to receive reports ?
              {/*
                guestFeedbackSummaryReport ? ' (Atleast one is required.)' : ''
              */}
            </p>
            <div className="alertOptions">
              {
                alertMediumOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={guestFeedbackSummaryReportMediumValues[item.id]}
                    onCheckBoxChange={onGuestFeedbackSummaryAlertMediumCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="gBCtRw sndNw">
          <div className="lt">
            <p className="title">Do you want to send a report now ?</p>
          </div>
          <div className="rt">
            <div className="selGrp">
              <Select
                options={sendPeriodOptions}
                onChange={onSendGuestFeedbackSummaryReportPeriodChange}
                value={selectedSendGuestFeedbackSummaryReportPeriodValue}
                inputMode={"none"}
              />
            </div>
            <div className={'selGrp ' + (selectedSendGuestFeedbackSummaryReportPeriodValue.value ? selectedSendGuestFeedbackSummaryReportPeriodValue.value : '')}>
              <Select
                options={guestFeedbackPeriodOptions}
                onChange={onGuestFeedbackPeriodChange}
                value={selectedGuestFeedbackPeriodValue}
                inputMode={"none"}
              />
            </div>
            <button className="gBtn" onClick={onGuestFeedbackSummaryReportSendNowClick}>
              Send Now
              {
                userState.user.user_info && userState.user.user_info.profile &&
                userState.user.user_info.profile['send_guest_feedback_summary_report_InProgress'] &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderReviewSummaryReportBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Review Summary Report: {reviewSummaryReport ? 'ON' : 'OFF'}</p>
            <p className="desc">Review summary.</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="reviewSummaryReport" checked={reviewSummaryReport} onChange={onReviewSummaryReportChange}/><label htmlFor="reviewSummaryReport">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts rating' + (!reviewSummaryReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (reviewSummaryReportRatingValuesError ? 'error' : '')}>
              Set the ratings (Required)
            </p>
            <div className="alertOptions">
              {
                ratingOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={reviewSummaryReportRatingValues[item.id]}
                    onCheckBoxChange={onReviewSummaryRatingCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
            {
              reviewSummaryReportRatingValuesError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
        </div>
        <div className={'gBCtRw alrtOpts frequency' + (!reviewSummaryReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (reviewSummaryReportFrequencyValuesError ? 'error' : '')}>
              How often would you like to receive this report?
              {
                reviewSummaryReport ? ' (Atleast one is required.)' : ''
              }
            </p>
            <div className="alertOptions">
              {
                reviewSummaryFrequencyOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.value}
                    name={item.value}
                    label={item.label}
                    value={reviewSummaryReportFrequencyValues[item.value]}
                    onCheckBoxChange={onReviewSummaryFrequencyCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
            {
              reviewSummaryReportFrequencyValuesError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
        </div>
        <div className={'gBCtRw alrtOpts medium' + (!reviewSummaryReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (reviewSummaryReportMediumValuesError ? 'error' : '')}>
              How would you like to receive reports ?
              {/*
                reviewSummaryReport ? ' (Atleast one is required.)' : ''
              */}
            </p>
            <div className="alertOptions">
              {
                alertMediumOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={reviewSummaryReportMediumValues[item.id]}
                    onCheckBoxChange={onReviewSummaryAlertMediumCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="gBCtRw sndNw">
          <div className="lt">
            <p className="title">Do you want to send a report now ?</p>
          </div>
          <div className="rt">
            <div className={'selGrp ' + (selectedSendReviewSummaryReportPeriodValue.value ? selectedSendReviewSummaryReportPeriodValue.value : '')}>
              <Select
                options={reviewSummaryRatingOptions}
                onChange={onReviewSummaryRatingChange}
                value={selectedReviewSummaryRatingValue}
                inputMode={"none"}
              />
            </div>
          </div>
          <div className="rt">
            <div className="selGrp">
              <Select
                options={sendPeriodOptions}
                onChange={onSendReviewSummaryReportPeriodChange}
                value={selectedSendReviewSummaryReportPeriodValue}
                inputMode={"none"}
              />
            </div>
            <div className={'selGrp ' + (selectedSendReviewSummaryReportPeriodValue.value ? selectedSendReviewSummaryReportPeriodValue.value : '')}>
              <Select
                options={reviewSummaryPeriodOptions}
                onChange={onReviewSummaryPeriodChange}
                value={selectedReviewSummaryPeriodValue}
                inputMode={"none"}
              />
            </div>
            <button className="gBtn" onClick={onReviewSummaryReportSendNowClick}>
              Send Now
              {
                userState.user.user_info && userState.user.user_info.profile &&
                userState.user.user_info.profile['send_review_summary_report_InProgress'] &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderOopsReportBox = () => {
    return (
      <>
        <div className="gBCtRw hasTgl">
          <div className="lt">
            <p className="title">Oops Report: {oopsReport ? 'ON' : 'OFF'}</p>
            <p className="desc">Oops report</p>
          </div>
          <div className="rt">
            <div className="gToggleWrap">
              <div className="gToggle">
                <input type="checkbox" id="oopsReport" checked={oopsReport} onChange={onOopsReportChange}/><label htmlFor="oopsReport">Toggle</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'gBCtRw alrtOpts frequency' + (!oopsReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (oopsReportFrequencyValuesError ? 'error' : '')}>
              How often would you like to receive this report?
              {
                oopsReport ? ' (Atleast one is required.)' : ''
              }
            </p>
            <div className="alertOptions">
              {
                oopsFrequencyOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={oopsReportFrequencyValues[item.id]}
                    onCheckBoxChange={onOopsReportFrequencyCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
            {
              oopsReportFrequencyValuesError &&
              <div className="gErr">
                Required
              </div>
            }
          </div>
        </div>
        <div className={'gBCtRw alrtOpts medium' + (!oopsReport ? ' inactive' : '')}>
          <div className="lt">
            <p className={'title ' + (oopsReportValuesError ? 'error' : '')}>
              How would you like to receive reports ?
              {/*
                oopsReport ? ' (Atleast one is required.)' : ''
              */}
            </p>
            <div className="alertOptions">
              {
                alertMediumOptions.map((item) => {
                  return <CheckBoxRow
                    id={item.id}
                    name={item.id}
                    label={item.label}
                    value={oopsReportValues[item.id]}
                    onCheckBoxChange={onOopsMediumCheckBoxChange}
                    user={userState.user}
                  />
                })
              }
            </div>
          </div>
        </div>
        <div className="gBCtRw sndNw">
          <div className="lt">
            <p className="title">Do you want to send a report now ?</p>
          </div>
          <div className="rt">
            <div className="selGrp">
              <Select
                options={sendPeriodOptions}
                onChange={onSendOopsReportPeriodChange}
                value={selectedSendOopsReportPeriodValue}
                inputMode={"none"}
              />
            </div>
            <div className={'selGrp ' + (selectedSendOopsReportPeriodValue.value ? selectedSendOopsReportPeriodValue.value : '')}>
              <Select
                options={oopsPeriodOptions}
                onChange={onOopsReportPeriodChange}
                value={selectedOopsReportPeriodValue}
                inputMode={"none"}
              />
            </div>
            <button className="gBtn" onClick={onOopsReportSendNowClick}>
              Send Now
              {
                userState.user.user_info && userState.user.user_info.profile &&
                userState.user.user_info.profile['send_oops_report_InProgress'] &&
                <span><i className="fa fa-spinner fa-spin" /></span>
              }
            </button>
          </div>
        </div>
      </>
    );
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('Reports: ', props);
    console.log('Reports guestFeedbackSummaryReportFrequencyValuesError: ', guestFeedbackSummaryReportFrequencyValuesError);
    console.log('Reports performanceReportFrequencyValuesError: ', performanceReportFrequencyValuesError);
    console.log('Reports reviewSummaryReportRatingValuesError: ', reviewSummaryReportRatingValuesError);
    console.log('Reports reviewSummaryReportFrequencyValuesError: ', reviewSummaryReportFrequencyValuesError);
    console.log('Reports oopsReportFrequencyValuesError: ', oopsReportFrequencyValuesError);
    console.log('Reports oopsReportFrequencyValues: ', oopsReportFrequencyValues);
    console.log('performanceReportFrequencyValues: ', performanceReportFrequencyValues);
  }

  let hasGroupOverviewReport = false;
    if (userState.user.user_info && userState.user.user_info.profile &&
      (
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GT_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.PARTNER_MANAGER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.OWNER ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_READ_ONLY ||
        userState.user.user_info.profile.access_level === ACCESS_LEVELS.GROUP_ADMIN_NO_USR_MGMT
      )
    ) { hasGroupOverviewReport = true; }

  return (
    <div className="gPScrlWrp hDGen flHt">
      <div className="gPWrp hDGen setRepAlrt">
        <div className="gPTle title">
          Reports
        </div>
        <div className="gBCtnt">
          { renderGroupOverviewReportBox() }
          { renderMonthlyPerformanceReportBox() }
          { renderGuestFeedbackSummaryReportBox() }
          { renderReviewSummaryReportBox() }
          { renderOopsReportBox() }
          {
            (
              (hasGroupOverviewReport && groupOverviewReportFrequencyValuesError) ||
              guestFeedbackSummaryReportFrequencyValuesError ||
              performanceReportFrequencyValuesError ||
              reviewSummaryReportRatingValuesError ||
              reviewSummaryReportFrequencyValuesError ||
              oopsReportFrequencyValuesError
            ) &&
            <div className="gErr">
              Please check above errors!
            </div>
          }
          <div className="gBCtRw btnRw">
            <button type="submit" className={'gBtn ok ' + (formChanged ? '' : 'disabled ')} onClick={onSubmit} >
              Save Changes
              {
                props.locationProfile && props.locationProfile.reputation &&
                props.locationProfile.reputation.reports &&
                props.locationProfile.reputation.reports.editInProgress
                && <i className="fa fa-spinner fa-spin" />
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
